import React, { useEffect, useContext, useRef, useState} from "react";
import { Form, Button, Menu, Row, Col, Input } from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import { UserOutlined, CalendarOutlined  } from '@ant-design/icons';
import AgendaMovil from '../evento/AgendaMovil';

import '../../assets/css/perfil.css';

import actualizar from '../../assets/images/03_mi_perfil/03-actualizar.png';

const app = new Firebase();

const nombre_webinar = 'canacem2021';


const Perfil=({history}) =>{

    const { usuario, datos, setDatos } = useContext(Auth);
    const [form] = Form.useForm();
    const ref_form = useRef(null);
    const [seccion, setSeccion] = useState('datos');

    useEffect(() => {
        
        if (usuario===null) {
            history.push("/login");
        }
        
    }, [history, usuario]);


    const onFinish = async values => {
        app.escribir(nombre_webinar+'/usuarios',datos.key,{...datos,...values}, (data)=>{
            console.log(data);
            setDatos(data);
        });
    };
    
        return (

            <Row className="contenedor_perfil">
              <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
                <Row align="top">
                <Col span={24}>
                        <p className="texto_bienvenido_perfil">
                            Bienvenid@: <strong>{`${datos.nombre} ${datos.ap_paterno} ${datos.ap_materno}`}</strong>
                        </p>
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:5, offset:0}} className="contenedor_secciones_perfil">
                    <p className={seccion === 'datos' ? 'seccion_activa_perfil' : 'seccion_perfil'} onClick={()=>setSeccion('datos')}>
                        <UserOutlined className="icono_seccion_perfil" /> <span className="nombre_seccion_perfil">Mis datos</span>
                    </p>
                    <p className={seccion === 'agenda' ? 'seccion_activa_perfil' : 'seccion_perfil'}  onClick={()=>setSeccion('agenda')}>
                        <CalendarOutlined className="icono_seccion_perfil" /> <span className="nombre_seccion_perfil">Agenda</span>
                    </p>
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:17, offset:1}}>
                  {seccion === 'agenda' ?
                    <AgendaMovil />
                    :  
                
                <Form className=""
        ref = {ref_form}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          ...datos
        }}
        onFinish={onFinish} 
        >

          <Row style={{marginLeft:'20px',marginRight:'20px'}}>
            
            <Col span={24}>
            <Form.Item
        name="nombre"
        label={<span className="texto_label_form">Nombre(s)</span>}
        rules={[
          
          {
            required: true,
            message: <span className="label_requerido_registro">Ingresa tu nombre!</span>,
          },
        ]}
      >
        <Input className="input_perfil" />
      </Form.Item>
            </Col>
            <Col xs={{span:24}} >
            <Form.Item
        name="ap_paterno"
        label={<span className="texto_label_form">Apellido paterno</span>}
        rules={[
          
          {
            required: true,
            message: <span className="label_requerido_registro">Ingresa tu apellido paterno!</span>,
          },
        ]}
      >
        <Input className="input_perfil" />
      </Form.Item>
            </Col>
            <Col xs={{span:24}}>
            <Form.Item
        name="ap_materno"
        label={<span className="texto_label_form">Apellido materno</span>}
        rules={[
          
          {
            required: true,
            message: <span className="label_requerido_registro">Ingresa tu apellido materno!</span>,
          },
        ]}
      >
        <Input className="input_perfil" />
      </Form.Item>
            </Col>
           
           
           
            <Col span={24}>
            <Form.Item
        name="empresa"
        label={<span className="texto_label_form">Empresa</span>}
        rules={[
          
          {
            required: true,
            message: <span className="label_requerido_registro">Ingresa el nombre de tu empresa!</span>,
          },
        ]}
      >
        <Input className="input_perfil" />
      </Form.Item>
            </Col>
           
           
            
            <Col span={24}>
            <Form.Item>
              <br />

              <p style={{textAlign:'center'}}>
                <button htmlType="submit" className="boton_actualizar_perfil">
                  <img src={actualizar} className="imagen_actualizar_perfil" />
                </button>
              </p>
                
                </Form.Item>
            </Col>
          </Row>
          
        </Form>
}
                </Col>
                </Row>
              </Col>
              <Col span={24}>
                <br /><br />
              </Col>
                </Row>

           
        );
    
}
export default withRouter(Perfil);
