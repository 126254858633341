import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import '../../assets/css/ponentes.css';

import ponente_1 from '../../assets/images/sitio/ponentes/images/FELIPE_olivares.png';
import ponente_2 from '../../assets/images/sitio/ponentes/images/ILEANAruiz.png';
import ponente_3 from '../../assets/images/sitio/ponentes/images/RAUL_ROJAS.png';
import ponente_4 from '../../assets/images/sitio/ponentes/images/HECTOR_MIGUEL.png';
import ponente_5 from '../../assets/images/sitio/ponentes/images/IRMA_SONIA_VENTURA.png';
import ponente_6 from '../../assets/images/sitio/ponentes/images/OMAR_TENORIO.png';
import ponente_7 from '../../assets/images/sitio/ponentes/images/SILVIA_ROJAS.png';


const ponentes = [
  {img:ponente_1,alt:'felipe-olivares',tipe:1,nombre:'FELIPE OLIVARES',empresa:'DUPONT',titulo:'',tag:'#felipe-olivares',
  texto:['Director de DuPont Sustainable Solutions, firma de consultoría de gestión de riesgos operativos.  Cuenta con más de 20 años de experiencia con empresas de asesoría técnica habiendo trabajado en proyectos de implementación y mejora de operaciones para empresas líder en los sectores de manufactura, oil & gas, metales y minería en México, Centroamérica, E.U., Brasil, Argentina y el Sur de Asia.']},
  {img:ponente_2,alt:'ileana-ruiz',tipe:2,nombre:'ILEANA RUÍZ SÁNCHEZ',empresa:'',titulo:'',tag:'#ileana-ruiz',
  texto:['Maestría en Dirección del Talento Humano por el Instituto de Estudios Universitarios de Puebla. ',
  'Licenciatura en Psicología Educativa por la Universidad Pedagógica Nacional. ',
  'Especialista en Terapia Breve.  ',
  'Diplomado en Habilidades Directivas por el Instituto de Estudios Universitarios de Puebla. ',
  'Curso de Tanatologia por la Asociación Mexicana de Tanatología.',
  'Diplomado en Bienestar Laboral y NOM.035 (en curso).',
  'Cuenta con diversos diplomados en equidad de Género, atención de la violencia contra las mujeres y grupos vulnerables, así como para la creación e implementación de estrategia de bienestar laboral y para el manejo del estrés, manejo del duelo, entre otros.',
  'Ha sido facilitadora de diversos talleres y ponencias a nivel Latinoamérica en diversas organizaciones gubernamentales y privadas en temas de salud y bienestar laboral.',
  'Laboró dentro del Instituto de las Mujeres del Distrito Federal participando en la creación de políticas públicas a favor de los derechos de las mujeres y brindando asesorías psicológicas, facilitando Grupos de Psicoterapia Breve y Grupos de Prevención de la Violencia contra las Mujeres.',
  'Tiene amplia experiencia, en la creación de estrategias de salud mental y creación e implementación de programas wellness en diversas empresas.',
  'Está a cargo de la Gerencia de manejo de cuentas de ICAS México, así como de la RED de expositores a nivel nacional y Latinoamérica y del desarrollo contenidos para Ios EAP, así mismo se encarga del diseño e implementación de estos programas en las empresas, actividad que combina con la consulta privada.']},
  {img:ponente_3,alt:'raul-rojas',tipe:2,nombre:'RAÚL ROJAS GONZÁLEZ',empresa:'Gerente Nacional de Seguridad e Instalaciones,CEMEX México',titulo:'',tag:'#raul-rojas',
  texto:['Graduado como Licenciado en Criminología por la Universidad Autónoma de Nuevo León y Maestro en Criminología por el Centro de Estudios Avanzados de las Américas.',
  'Graduado de los Programas de Liderazgo y Management por la Universidad Georgetown de Washington, D.C y por la Escuela de Negocios de Kellogg de la Universidad Northwestern de Chicago, Illinois, Estados Unidos.',
  'Profesional de Seguridad Corporativa por más de 20 años en Multinacionales como British American Tobacco México, Industrias John Deere, Grupo Soriana y Nokia Siemens Networks. ',
  'Gerente Regional de Seguridad Corporativa para las Américas y Gerente Nacional de Seguridad e Instalaciones en CEMEX.',
  'Catedrático de Posgrado en Criminología y Miembro del Consejo Consultivo Externo en la Universidad Metropolitana de Monterrey y la Universidad Autónoma de Nuevo León. ',
  'Ponente en Seminarios de Seguridad y Criminología Corporativa en Universidades Públicas y Privadas en México.',
  'Presidente del Comité de Seguridad de la Cámara de Comercio de Estados Unidos en Monterrey (AMCHAM) a partir de 2020.',
  'Medalla del Instituto de Políticas Públicas Contra el Narcotráfico de la Universidad Nacional de Lomas de Zamora de Argentina.',
  'Condecoración de la Dirección de Antisecuestro y Antiextorsión (GAULA) de la Policía Nacional de Colombia.',
  'Reconocimiento Worldwide Partner de la multinacional DOW Chemical Company por las Alianzas Estratégicas de Seguridad en Latinoamérica.',
  'Reconocimiento a la Excelencia en el Desarrollo Profesional de la Universidad Autónoma de Nuevo León como egresado distinguido.']},
  {img:ponente_4,alt:'hector-miguel',tipe:1,nombre:'HÉCTOR MIGUEL GARCÍA ',empresa:'Holcim México',titulo:'',tag:'#hector-miguel',
  texto:['Ingeniero mecánico con una especialidad en Salud y Seguridad Industrial y certificado como coaching ejecutivo.',
'Cuenta con más de 10 años de experiencia en la industria del cemento dentro de las áreas de manufactura en la fabricación de cemento y en el área de soporte HSSE',
'Actualmente se desempeña como responsable de cultura y competencias en seguridad para Holcim México.']},
  {img:ponente_5,alt:'irma-sonia-ventura',tipe:1,nombre:'IRMA SONIA VENTURA FLORES',empresa:'Holcim México',titulo:'',tag:'#irma-sonia-ventura',
  texto:['Ella se e graduó como Médico Cirujano de la Facultad de Estudios Superiores Iztacala, en la UNAM. Cuenta con una Maestría en Seguridad e Higiene por la Secretaría del Trabajo.',
'Con más de 10 años de experiencia en Salud Ocupacional e Higiene Industria. Ha trabajado para distintas empresas internacionales como Ford Motor Company, American Express, Halliburton.',
'En el 2016 ingresó al área de H&S para ocupar la Coordinación Nacional de Salud Ocupacional e Higiene Industrial para Holcim México.']},
  {img:ponente_6,alt:'omar-enorio',tipe:1,nombre:'Omar Tenorio',empresa:'Holcim México',titulo:'',tag:'#omar-enorio',
  texto:['Licenciado en Negocios Internacionales por el IPN, bilingüe, con extensa experiencia en sistemas de gestión de seguridad vial, continuidad de negocio y manejo de crisis.',
'Es responsable de la estrategia de Seguridad Vial para Holcim México desde 2019 con amplia participación a nivel latinoamérica, ha logrado importantes resultados en la reducción de accidentabilidad a través de sólidos procesos e innovaciones tecnológicas.',
'En 2020, en conjunto con el área logística obtuvieron el Premio Nacional de Seguridad Vial otorgado por la ANTP gracias a los extraordinarios resultados y nivel de madurez del sistema de gestión de seguridad vial implementado en las operaciones de Holcim México.']},
{img:ponente_7,alt:'',tipe:1,nombre:'SILVIA ROJAS',empresa:'Aura Interactiva',titulo:'',tag:'#',
texto:['Como parte de su carrera profesional se destaca su experiencia en empresas transnacionales del sector de tecnología como Gerente de Producto y Estrategia de Aura Interactiva.',
'Es licenciada en Dirección de Empresas de la Universidad de Costa Rica (UCR), Global Máster in Business Administration (MBA) de ESEUNE Buiness School, Georgetown University y North China University of Technology.',
'Asimismo, es Magíster Scientiae (MSc.) infieri en Desarrollo Sostenible con énfasis en Formación y Promoción Ecológica de la Universidad de Costa Rica (UCR).']},
]
const Ponentes = (props) => {

  let ver_ponentes = ponentes.map((objeto) => {
    return (
      <>
      <Element name={objeto.tag} />
      <Col xs={{span:10, offset:7}} md={{span:6, offset:2}}>
        <img src={objeto.img} alt={objeto.texto} className="img_sec_ponentes" />
      </Col>
      <Col xs={{span:20, offset:2}} md={{span:13, offset:1}}>
        <p className="nombre_ponente">{objeto.nombre}</p>
        <p className="empresa_ponente">{objeto.empresa}</p>
        <p className="conferencia_ponente">{objeto.titulo}</p>
        {objeto.tipe === 1 ? 
          objeto.texto.map((parrafo,index) => {
            if(index === 0){
              return(
              <p className="parrafo_ponente">{parrafo}</p>
              );
            }else{
              return(
                <p className="parrafo_ponente">{parrafo}</p>
              );
            }
          })
          :
          <ul className="parrafo_ponente">
            {objeto.texto.map((parrafo,index) => {
            if(index === 0){
              return(
              <li>{parrafo}</li>
              );
            }else{
              return(
                <li>{parrafo}</li>
              );
            }
          })}
          </ul>
        }
        <br /><br />
      </Col>
      </>
    )
  })

    return (
      <Row>
        <Col span={24}>
        <br /><br /><br /><br />
        </Col>
        {ver_ponentes}
      </Row>
    );

};

export default Ponentes;