import React, {useState, useEffect, useContext} from 'react';
import '../../assets/css/admin.css';
import Firebase from '../../utils/firebaseConfig';
import { Row, Col, Select, notification  } from "antd";
import { Auth } from "../../utils/AuthContext";
import { faIgloo } from '@fortawesome/free-solid-svg-icons';

const nombre_webinar = 'canacem-21';

const mensaje = (tipo, titulo, texto) =>{
  notification[tipo]({
      message: titulo,
      description: texto,
      duration: 10,
    });
}

const lives = [
  {nombre:'Estadísticas del sector y accidentes graves',url:'https://www.youtube.com/embed/G_bgRfcDjW4',dia:1,video:1},
  {nombre:'Protocolos de actuación ante amenazas biológicas',url:'https://www.youtube.com/embed/OikK8Z1CDss',dia:1,video:2},

  {nombre:'Gestión de Contratistas',url:'https://www.youtube.com/embed/WqXu0H5NcGg',dia:2,video:1},
  {nombre:'Cómo lograr el aprendizaje en modalidad virtual',url:'https://www.youtube.com/embed/dE8g_xJnhrU',dia:2,video:2},

  {nombre:'Prevención del delito',url:'https://www.youtube.com/embed/uN5FrIMpcE8',dia:3,video:1},
  {nombre:'Seguridad Vial',url:'https://www.youtube.com/embed/uN5FrIMpcE8',dia:3,video:2},
  {nombre:'Entrega de Premio mejora en el desempeño en seguridad',url:'https://www.youtube.com/embed/YsDRPdW2qpw',dia:4,video:1},
  //{nombre:'Implementación Nom-035',url:'https://www.youtube.com/embed/vRyziW5pzVs',dia:4,video:2},
  {nombre:'Retorno al trabajo y resiliencia',url:'https://www.youtube.com/embed/CeQOajHGRfA',dia:4,video:3},
]

const firebase = new Firebase();

const { Option } = Select;

const acceso_admin = ['Martin@thethree.com.mx','martin@thethree.com.mx','martha@thethree.com.mx','marco@thethree.com.mx',
                      'andy@thethree.com.mx','mario@thethree.com.mx','federico@zeraus.com.mx',
                      'j.villasenor1021@gmail.com'];

const Admin = (props) => {

  const [questions, setQuestions] = useState([]);
  const [questions1, setQuestions1] = useState([]);
  const [questions2, setQuestions2] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [questions4, setQuestions4] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [dia, setDia] = useState(4);
  const [pop_pregunta, setPopPregunta] = useState(-1);
  const [num_conferencia, setNumConferencia] = useState(3);

  const { usuario, datos } = useContext(Auth);

  useEffect(()=>{

    if(usuario){
      firebase.obtener_objetos(nombre_webinar+`/configuracion`,false,(data)=>{
        setPopPregunta(data[0].pregunta_popup);
        //setDia(data[0].dia);


        firebase.obtener_objetos(nombre_webinar+`/preguntas-publico1`,true,(data)=>{
            setQuestions1(data);
        });
        firebase.obtener_objetos(nombre_webinar+`/preguntas-publico2`,true,(data)=>{
            setQuestions2(data);
        });
        firebase.obtener_objetos(nombre_webinar+`/preguntas-publico3`,true,(data)=>{
            setQuestions3(data);
        });
        firebase.obtener_objetos(nombre_webinar+`/preguntas-publico4`,true,(data)=>{
            setQuestions4(data);
        });



        firebase.obtener_objetos(nombre_webinar+'/preguntas-popup',false,(data) => {
          setPreguntas(data.filter(pregunta => pregunta.dia === data[0].dia));
        });
  
      });
      /*if(datos){
        const acceso = acceso_admin.filter(correo => correo === datos.correo).length;
        if(acceso > 0){
          firebase.obtener_objetos(nombre_webinar+`/configuracion`,false,(data)=>{
            setPopPregunta(data[0].pregunta_popup);
            setDia(data[0].dia);
            firebase.obtener_objetos(nombre_webinar+`/preguntas-publico${data[0].dia}`,true,(data)=>{
              setQuestions(data);
              console.log(data);
            });
            firebase.obtener_objetos(nombre_webinar+'/preguntas-popup',false,(data) => {
              setPreguntas(data.filter(pregunta => pregunta.dia === data[0].dia));
            });
      
          });
        }else{
          alert('no tienes acceso a esta seccion');
        }
      }else{
        alert('no tienes acceso a esta seccion');
      }*/
    }else{
      alert('no tienes acceso a esta seccion');
    }
  },[usuario,datos]);

  useEffect(()=>{},[dia]);

  useEffect(()=>{
    switch(dia){
      case 1: setQuestions(questions1); break;
      case 2: setQuestions(questions2); break;
      case 3: setQuestions(questions3); break;
      case 4: setQuestions(questions4); break;
    }
  },[questions1,questions2,questions3,questions4]);

  const aceptQuestion = (item) => {

    item['status'] = 'aceptada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{
      
    });
  };

  const rejectQuestion = (item) => {

    item['status'] = 'rechazada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const answerQuestion = (item) => {

    item['status'] = 'contestada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const retrieveQuestion = (item) => {

    item['status'] = 'pendiente';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const cambiarPreguntaPopUp = (e) => {

    firebase.escribir(nombre_webinar+'/configuracion','1234567890',{pregunta_popup: e, dia: dia}, ()=> {
      mensaje('success','Se actualizo la pregunta','');
    } );

  }

  

    let preguntas_pop_up = preguntas.map(pregunta => {

      return(
      <Option value={pregunta.num_pregunta}>{pregunta.pregunta}</Option>
      );
    });


    return (
      <>
      <Row>
        <Col span={20} offset={2} >
          <br /><br /><br /><br />

          <p className="admin_titulo" style={{display:'non'}}>LIVES</p>
          <Row>
            <Col span={6} style={dia === 1 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setDia(1); setQuestions(questions1);}}>DÍA 1</Col>
            <Col span={6} style={dia === 2 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setDia(2); setQuestions(questions2);}}>DÍA 2</Col>
            <Col span={6} style={dia === 3 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setDia(3); setQuestions(questions3);}}>DÍA 3</Col>
            <Col span={6} style={dia === 4 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setDia(4); setQuestions(questions4);}}>DÍA 4</Col>
          </Row>
          <br /><br />
          {lives.filter(live => live.dia === dia).map(live => {
            return(
              <p style={num_conferencia === live.video ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setNumConferencia(live.video)}}>
                {live.nombre}
              </p>
            );
          })

          }


        <p className="admin_titulo" style={{display:'none'}}>ENCUESTA SATISFACCIÓN</p>
        {pop_pregunta !== -1 &&
        
        <Select defaultValue={pop_pregunta} style={{ width: '100%' }} onChange={cambiarPreguntaPopUp} style={{display:'none'}}>
          <Option value={0}>Desactivar</Option>
          { preguntas_pop_up }
        </Select>
        }
        </Col>
      </Row>

      <Row>

        <Col span={24} offset={0}>
          <p className="admin_titulo">PREGUNTAS</p>
        </Col>
        <Col></Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">PENDIENTES</p>
            </Col>
            <Col span={24} >
            {questions.filter(pregunta => pregunta.num_conferencia === num_conferencia).map((item,idx)=>{
              return  item.status === 'pendiente' ? (
              <div className="questionContainer" key={item.key}>
                <p className="question"><span className="nombre_pregunta">{item.nombre}</span><br /> {item.organizacion}</p>
                <p className="question"><strong>{item.pregunta}</strong></p>
                <p className="botones">
                  <button className="rejectButton" onClick={()=>{
                    rejectQuestion(item);
                  }}>RECHAZAR</button>
                  <button className="acceptButton" onClick={()=>{
                    aceptQuestion(item);
                  }}>ACEPTAR</button>
                </p>
                </div>) : null;
            })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">ACEPTADAS</p>
            </Col>
            <Col span={24}>
            {questions.filter(pregunta => pregunta.num_conferencia === num_conferencia).map((item,idx)=>{
          return  item.status === 'aceptada' ? (
          <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span><br /> {item.organizacion}</p>
            <p className="question"><strong>{item.pregunta}</strong></p>
            <p className="botones">
            <button className="rejectButton" onClick={()=>{
                    rejectQuestion(item);
                  }}>RECHAZAR</button>
            <button className="answerButton" onClick={()=>{
              answerQuestion(item);
            }}>CONTESTADA</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">RECHAZADAS</p>
            </Col>
            <Col span={24}>
            {questions.filter(pregunta => pregunta.num_conferencia === num_conferencia).map((item,idx)=>{
            return  item.status === 'rechazada' ? (
            <div className="questionContainer" key={item.key}>
              <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}</p>
              <p className="question"><strong>{item.pregunta}</strong></p>
              <p className="botones">
                <button className="retrieveButton" onClick={()=>{
              retrieveQuestion(item);
            }}>RECUPERAR</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">CONTESTADAS</p>
            </Col>
            <Col span={24} >
            {questions.filter(pregunta => pregunta.num_conferencia === num_conferencia).map((item,idx)=>{
            return  item.status === 'contestada' ? (
            <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}</p>
            <p className="question"><strong>{item.pregunta}</strong></p>
            <p className="botones">
            <button className="acceptButton" onClick={()=>{
                    aceptQuestion(item);
                  }}>ACEPTAR</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
          <br /><br /><br />
        </Col>
        
      </Row>
    </>)
  
}

export default Admin;