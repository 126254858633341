import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import {Link, useLocation} from 'react-router-dom';
import '../../assets/css/evento.css';

import Contador from  './Contador';
import CarouselPonentes from './CarouselPonentes';
import Agenda from './Agenda';
import Whatsapp from '../master/Whatsapp';
import { Element } from 'react-scroll';
import Premios from './Premios';

import logo1 from '../../assets/images/sitio/empresas/logo_fortaleza.png';
import logo2 from '../../assets/images/sitio/empresas/logo_moctezuma_2.png';
import logo3 from '../../assets/images/sitio/empresas/logo_cycnaNacionales.png';
import logo4 from '../../assets/images/sitio/empresas/logo_cemex.png';
import logo5 from '../../assets/images/sitio/empresas/logo_cruzaz.png';
import logo6 from '../../assets/images/sitio/empresas/logo_cycnaoriente.png';
import logo7 from '../../assets/images/sitio/empresas/logo_gcc.png';
import logo8 from '../../assets/images/sitio/empresas/logo_holcim.png';

import logo_banner from '../../assets/images/sitio/home/banner_cancelacionCONFERENCIA_.jpg';
import horario_programa from '../../assets/images/sitio/home/horas.png';


const empresas = [
  {img:logo1,link:'/canacem#cemeex'},
  {img:logo2,link:'/canacem#cruz-azul'},
  {img:logo3,link:'/canacem#fortaleza'},
  {img:logo4,link:'/canacem#gcc'},
  {img:logo5,link:'/canacem#holcim'},
  {img:logo6,link:'/canacem#moctezuma'},
  {img:logo7,link:'/canacem'},
  {img:logo8,link:'/canacem'},
];

const programa = [
  {
    dia:'Lunes', num_dia:'26',
    conferencias:[
      {
        hora: '09:00 hr', nombre: 'Estadísticas del sector y accidentes graves', sub:'Comisión de seguridad y salud ocupacional CANACEM', ponentes:['Jenny Quintero',
      'Diana Zugasti', 'Miguel Garcia', 'Maribel Leyte', 'Oscar Cirilo','Eduardo Ituarte', 'Luis Mendoza','Ismael Lima', 'Juan Ramón Ponce','Rosendo Valdez','Héctor Miguel García'],existe:true
      },
      {
        hora: '12:00 hr', nombre: '', ponentes:[''],existe:false
      }
      ,
      {
        hora: '16:00 hr', nombre: 'Protocolos de actuación ante amenazas biológicas', sub:'', ponentes:['Irma Sonia Ventura Flores'],existe:true
      }
    ]
  },
  {
    dia:'Martes', num_dia:'27',
    conferencias:[
      {
        hora: '09:00 hr', nombre: 'Gestión de Contratistas', sub:'', ponentes:['Felipe Olivares, Dupont'],existe:true
      },
      {
        hora: '12:00 hr', nombre: '', ponentes:[''],existe:false
      }
      ,
      {
        hora: '16:00 hr', nombre: 'Cómo lograr el aprendizaje en modalidad virtual', sub:'', ponentes:['Silvia Rojas', 'Raúl Centeno', 'Héctor Miguel García'],existe:true
      }
    ]
  },
  {
    dia:'Miércoles', num_dia:'28',
    conferencias:[
      {
        hora: '09:00 hr', nombre: 'Prevención del delito ', sub:'', ponentes:['Raúl Rojas, Cemex México'],existe:true
      },
      {
        hora: '12:00 hr', nombre: '', sub:'', ponentes:[''],existe:false
      }
      ,
      {
        hora: '16:00 hr', nombre: 'Seguridad Vial', sub:'', ponentes:['Omar Tenorio, Holcim México'],existe:true
      }
    ]
  },
  {
    dia:'Jueves', num_dia:'29',
    conferencias:[
      {
        hora: '09:00 hr', nombre: 'Entrega de Premio mejora en el desempeño en seguridad', sub:'', ponentes:[''],existe:true
      },
      {
        hora: '12:00 hr', nombre: 'Implementación Nom-035', sub:'', ponentes:['Dr. Joaquín Apolonio-STPS','Por confirmar'],existe:true
      }
      ,
      {
        hora: '16:00 hr', nombre: 'Retorno al trabajo y resiliencia', sub:'', ponentes:['Ileana Ruiz'],existe:true
      }
    ]
  }
];

const Evento = (props) =>{

  const visualizar_programa = programa.map(dia => {

    const conferencias = dia.conferencias.map(conferencia => {
      return(
        <Col xs={{span:20, offset:2}} md={{span:6, offset:0}}>
          {conferencia.existe &&
            <>
              <p className="nombre_conferencia color_azul">
                {conferencia.nombre}
              </p>
              <p className="sub_conferencia color_azul">
                {conferencia.sub != '' ?
                conferencia.sub
                :
                <></>
                }
              </p>
              <p className="ponentes_conferencia">
                {conferencia.ponentes.map(ponente => {
                  return(
                    <>
                      {ponente}
                      <br />
                    </>
                  );
                })
                }
              </p>
              <p className="hora_movil_conferencia color_gris">
                {conferencia.hora}
              </p>
            </>
          }
        </Col>
      )
    });

    return(
      <Row align="middle" className="contenedor_dia_conferencia">
        <Col xs={{span:20, offset:2}} md={{span:6, offset:0}} >
          <p className="dia_conferencia color_gris">
            {dia.dia}
          </p>
          <p className="num_dia_conferencia color_gris">
            {dia.num_dia}
          </p>
        </Col>
        { conferencias }
      </Row>
    );
  });

  return(
    <>
    <Row justify="center" align="middle">
      <Col span={24}>
        <br /><br /><br />
      </Col>
      <Col xs={24} md={0}>
        <br />
      </Col>
      <Col xs={24} md={24} className="">
        <img src={logo_banner} width="100%" />
      </Col>
      <Col xs={20} md={16} >
        <br /><br />
        <h1 className="titulo_home_texto">COMISIÓN DE SEGURIDAD Y SALUD OCUPACIONAL DE LA CÁMARA NACIONAL DE CEMENTO</h1>
        <p className="parrafo_home_texto">
Compartir y analizar mejores prácticas operativas que coadyuven en la mejora del desempeño de seguridad y salud, con el objetivo de disminuir accidentes y prevenir lesiones o enfermedades laborales en los empleados, contratistas y terceros en el sector de fabricación del cemento en México.
</p>
      </Col>
      </Row>
      
    <Element name="#programa" />
    <Row align="middle">
      <Col span={24}>
        <p className="titulo_programa color_gris">
          PROGRAMA
        </p>
      </Col>
      <Col xs={{span:24, ofset:0}} md={{span:22, offset:1}}>
        <Row align="middle">
            <Col xs={{span:20, offset:2}} md={{span:6, offset:0}}>
            <p class="header_di_hora_conferencia color_azul">
              DÍA Y HORA
            </p>
          </Col>
          <Col xs={{span:0, offset:0}} md={{span:18, offset:0}}>
            <img src={horario_programa} className="img_horarios_programa" />
          </Col>
        </Row>
      {visualizar_programa}

      </Col>
      </Row>

      <Element name="#programa" />
    <Row>
      <Col span={20} offset={2}>
        <p className="titulo_programa color_gris">
        PREMIO MEJORA EN<br />EL DESEMPEÑO EN SEGURIDAD
        </p>
      </Col>
      <Col xs={{span:24, ofset:0}} md={{span:24, offset:0}}>
        <Premios />
      </Col>
      </Row>
    <Element name="#ponentes" />
    <Row justify="center">
      <Col span={24}>
        <p className="titulo_programa color_gris">
        PONENTES
        </p>
      </Col>
      <Col xs={{span:20, ofset:0}} md={{span:20, offset:0}}>
        <CarouselPonentes />
      </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p className="titulo_programa color_gris">
            CANACEM
          </p>
        </Col>
        {empresas.map(logo => {
          return(
            <Col xs={{span:12, offset:0}} md={{span:6, offset:0}}>
              <Link to={logo.link}>
              <img src={logo.img} alt={logo.link} className="logo_empresa" />
              </Link>
            </Col>
          )
        })

        }
        <Col span={24}>
          <br />
        </Col>
      </Row>
        
    </>
  );
}

export default Evento;