import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Row, Col, Typography, Select, Card } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

const { Text, Paragraph } = Typography;
const { Option } = Select;

const colors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#F369E5',
    '#2BCB18',
    '#F80606',
    '#5203E7',
    '#03E4E7',
    '#E3AE16',
    '#161FE3'
];

export const Estadisticas = (props) => {

    const [seleccion_estadistica, setSeleccionEstadistica] = useState(0);

    const { estadisticas, preguntas, idioma } = props;

    //console.log(preguntas);
    console.log(estadisticas);

    const [dataset, setDataSet] = useState([]);
    const [info_card, setInfCard] = useState([]);

    
    let show_preguntas = [];
    

    useEffect(()=>{

        let info_cards_pregunta = [];

        //Contabilizar votos por respuesta en cada pregunta y crear card de info de cada pregunta
        const datos = preguntas.map(pregunta => {

            let label_respuesta = [];
            let votos_respuesta = [];

            const info_card = Object.values(pregunta.respuestas).map(respuesta => {
                let votos = estadisticas.filter(usuario => {
                    
                    if(usuario.respondio){
                        
                        if(usuario.respuestas['pregunta' + pregunta.num_pregunta] === respuesta.respuesta ||
                        usuario.respuestas['pregunta' + pregunta.num_pregunta] === respuesta.respuesta_ingles){
                            return true;
                        }
                    }
                    return false;
                }).length;
                label_respuesta.push(respuesta.respuesta);
                votos_respuesta.push(votos);
                return([respuesta.respuesta, respuesta.respuesta_ingles,votos]);
            });

            info_cards_pregunta.push(info_card);

            return(
                {
                    labels: label_respuesta,
                    datasets: [{
                        data: votos_respuesta,
                        backgroundColor: colors,
                        hoverBackgroundColor: colors
                    }]
                }
            );
        });

        setDataSet(datos);
        setInfCard(info_cards_pregunta);


    },[estadisticas]);


    //select pregunta
    let div_select_pregunta = preguntas.map(pregunta => {

        show_preguntas.push(pregunta.pregunta);

        return (
            <Option value={pregunta.num_pregunta - 1}>{idioma === 'es' ? pregunta.pregunta : pregunta.pregunta_ingles}</Option>
        );

    });

    //info gráfica
    
    let divs_info_grafica = info_card.map((respuestas) => {

        const total = respuestas.map(valor => valor[2]).reduce((valorAnterior, valorActual) =>{
            return valorAnterior + valorActual;
        });

        const divs_respuestas = respuestas.map((respuesta,index)=> {
            return(
                <Paragraph>
                    <Row>
                        <Col xs={{span:2}} md={{span:2}}>
                            <MinusOutlined style={{ color:colors[index],backgroundColor:colors[index++] }} />
                        </Col>
                        <Col xs={{span:17}} md={{span:17}} span={19} style={{textAlign:'left', paddingLeft:'4px'}}>
                            {idioma === 'es' ?
                                <>{respuesta[0]}</>
                                :
                                <>{respuesta[1]}</>
                            }
                        </Col>
                        <Col xs={{span:5}} md={{span:5}} style={{textAlign:'left', paddingLeft:'4px', fontSize:'12px'}}>
                            <strong>{ (respuesta[2]*100/total).toFixed(2) } %</strong>
                        </Col>
                    </Row>
                </Paragraph>
            );
        });

        return (
            <Card headStyle={{backgroundColor:'#2776cc', color:'white'}} 
                title={<Text style={{textAlign:'left', color:'white'}}>{idioma === 'es' ? 'Resultados' : 'Results' }:</Text>} 
                style={{ width: '100%', border:'1px solid lightgray' }}>
                {divs_respuestas}
            </Card>
        );

    });

    const legend = {
        "display": false
    }

    return (
        <Row align="middle" style={{paddingBottom:'25px', backgroundColor: 'rgb(250,250,250)'}}>
            <Col span={24}>
                <Select defaultValue={seleccion_estadistica} style={{ width:'100%', border:'2px solid #000000', color:'#000000' }} onChange={(val) => { setSeleccionEstadistica(val); }}>
                    {div_select_pregunta}
                </Select>
                <br /><br />
            </Col>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 1 }} style={{display:'non'}}>
                {divs_info_grafica[seleccion_estadistica]}
            </Col>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 10, offset: 0 }}>
                <br />
                <Doughnut data={dataset[seleccion_estadistica]} legend={legend} style={{width:'100%'}} />
            </Col>
            
        </Row>


    );

}