import React from 'react';
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_logo_1 from '../../assets/images/sitio/registro/icono_candado.png';
//import img_logo_2 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_3 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_4 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_5 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_6 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_7 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_8 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_9 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_10 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_11 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_12 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_13 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_14 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_15 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_16 from '../../assets/images/sitio/registro/icono_candado.png';
import img_logo_17 from '../../assets/images/sitio/registro/icono_candado.png';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';


let logos = [
    { index: 0, img_src: img_logo_1, nombre: 'Ies_AcuityBrands' },
    //{ index: 1, img_src: img_logo_2, nombre: 'Ies_Beghelli' },
    { index: 2, img_src: img_logo_3, nombre: 'Ies_Construlita' },
    { index: 3, img_src: img_logo_4, nombre: 'Ies_Cooper' },
    { index: 4, img_src: img_logo_5, nombre: 'Ies_Elmsa' },
    { index: 5, img_src: img_logo_6, nombre: 'Ies_Hubbel' },
    { index: 16, img_src: img_logo_7, nombre: 'Ies_SolaBasic' },
    { index: 6, img_src: img_logo_8, nombre: 'Ies_Ledvance' },
    { index: 7, img_src: img_logo_9, nombre: 'Ies_Lumiance' },
    { index: 8, img_src: img_logo_10, nombre: 'Ies_Lutron' },
    { index: 9, img_src: img_logo_11, nombre: 'Ies_Osram' },
    { index: 10, img_src: img_logo_12, nombre: 'Ies_Regianni' },
    { index: 11, img_src: img_logo_13, nombre: 'Ies_Signify' },
    { index: 12, img_src: img_logo_14, nombre: 'Ies_Simon' },
    { index: 13, img_src: img_logo_15, nombre: 'Ies_Tci' },
    { index: 14, img_src: img_logo_16, nombre: 'Ies_Toliy' },
    { index: 15, img_src: img_logo_17, nombre: 'Ies_Zeraus' },
    
]


function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

const CarouselPatrocinadores = (params) => {

    const { num_items } = params;


    const settingslogos = {
        className: "center",
            centerMode: true,
            infinite: true,
            slidesToShow: num_items,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 200,
            speed:2000,
            dots:false
    };
    

    //logos = shuffle(logos);

    const div_logos = logos.map(logo => {
        return(
          <div className="div_patrocinador">
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'100%'}}  />
      </div>
        )
    });

    return (
      <div style={{marginTop:'0px', paddingtop:'0px',textAlign:'center', alignItems:'middle'}}>
      <Slider {...settingslogos}>
      {div_logos}
      </Slider>
     
  </div>
      
    );

};

export default CarouselPatrocinadores;