import React, {useState} from 'react';
import { Row, Col, Carousel } from 'antd';
import { Link } from 'react-router-dom';



import img1 from '../../assets/images/01_impacDemo_home/03-minuatura-transmision.jpg';

import entrar from '../../assets/images/01_impacDemo_home/03 boton-entrar.png';
import revivir from '../../assets/images/01_impacDemo_home/03 boton-revivelo.png';
import imglive from '../../assets/images/01_impacDemo_home/03-icono-live.png';



const progra = [
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Inauguración nombre del evento', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:img1, url:'/inauguracion/conferencia', link:revivir},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Primer conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:img1, url:'/primer-conferencia/conferencia', link:revivir},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:imglive, nombre:'Segunda conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:img1, url:'/en-vivo', link:entrar},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Tercer conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:'', url:'', link:''},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Cuarta conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:'', url:'', link:''},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Quinta conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:'', url:'', link:''},

  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Inauguración nombre del evento', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'2', img:'', url:'/', link:''},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Primer conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'2', img:'', url:'/', link:''},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Segunda conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'2', img:'', url:'/', link:''},

  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Inauguración nombre del evento', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'3', img:'', url:'/', link:''},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Quinta conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'3', img:'', url:'', link:''},

  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Inauguración nombre del evento', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'4', img:'', url:'/', link:''},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Primer conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'4', img:'', url:'/', link:''},
  {hora:'00:00 - 00:00', fecha:'31 de diciembre 2021', live:'', nombre:'Quinta conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'4', img:'', url:'', link:''},

];

const AgendaMovil = () =>{
  const [ver_dia, setVerDia] = useState(1);

  let dias_vista = progra.filter(dia =>{
    if(dia.dia == ver_dia){
      return true;
    }
    return false;
 });

  let visualizar_programa = dias_vista.map((seccion)=>{
    return(
      <div className="info-horario">
        
        <Row justify="center" align="middle">
          <Col span={4}>
            <p style={{ textAlign:'left' }}>
              <img alt="" style={{ width:'50%' }} src={seccion.live}/><br/>
              <span className="info-hora">{seccion.hora}</span><br />
              <span className="info-fecha">{seccion.fecha}</span>
              </p>
          </Col>
          
          <Col span={13}>
            <p>
            <span className="info-nombre"><strong>{seccion.nombre}</strong></span><br />
            <span className="info-descripcion"><strong>{seccion.descripcion}</strong></span></p>
          </Col>
          <Col span={3}>
            <img alt="" style={{ width:'100%' }} className="info-video" src={seccion.img} />
          </Col>
          <Col span={3}>
            <a href={seccion.url}>
              <img alt="" style={{ width:'70%' }} src={seccion.link}/>
            </a>
          </Col>
          <Col span={24}>
            <hr style={{ color:'#000000' }} />
          </Col>
        </Row>
      </div>
    );
  });
  
  let visualizar_programa_movil = dias_vista.map((seccion)=>{
    return(
      <div className="info-horario">
        
        <Row justify="center" align="middle">
          <Col span={10}>
            <p style={{ textAlign:'left' }}>
              <img alt="" style={{ width:'30%' }} src={seccion.live}/><br/>
              <span className="info-hora">{seccion.hora}</span><br />
              <span className="info-fecha">{seccion.fecha}</span>
              </p>
          </Col>
          
          <Col span={12}>
            <p>
            <span className="info-nombre"><strong>{seccion.nombre}</strong></span><br />
            <span className="info-descripcion"><strong>{seccion.descripcion}</strong></span></p>
            <a href={seccion.url}>
              <img alt="" style={{ width:'40%' }} src={seccion.link}/>
            </a>
          </Col>
          
          <Col span={20}>
            <hr style={{ color:'#000000' }} />
          </Col>
        </Row>
      </div>
    );
  });
  return(

    <Row justify="center" align="middle">
          <Col span={24} align="left" className="tituPrograma_perfil">
            
              <Row>
                <Col >
                  <Link className={ver_dia === 1 ? 'dia_agenda_home_select_perfil': 'dia_agenda_home_perfil'} onClick={() => {setVerDia(1)}}>DÍA 1</Link>
                </Col>
                <Col >
                <Link className={ver_dia === 2 ? 'dia_agenda_home_select_perfil': 'dia_agenda_home_perfil'} onClick={() => {setVerDia(2)}}>DÍA 2</Link>
                </Col>
                <Col>
                <Link className={ver_dia === 3 ? 'dia_agenda_home_select_perfil': 'dia_agenda_home_perfil'} onClick={() => {setVerDia(3)}}>DÍA 3</Link>
                </Col>
                <Col >
                <Link className={ver_dia === 4 ? 'dia_agenda_home_select_perfil': 'dia_agenda_home_perfil'} onClick={() => {setVerDia(4)}}>DÍA 4</Link>
                </Col>
              </Row>
              </Col>
          <Col span={24} >
          <Row justify="center" align="middle">
              <Col xs={0} lg={24} className="divPrograma_perfil">
                {visualizar_programa}
              </Col>
              <Col xs={24} lg={0} className="divPrograma_perfil">
                {visualizar_programa_movil}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <br/><br/><br />
          </Col>
        </Row>

);
}

export default AgendaMovil;