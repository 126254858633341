import React, { useEffect, useState } from 'react';
import '../../assets/css/general.css';
import {Element, scroller} from 'react-scroll';

import Header from './Header';
import Footer from './Footer';
import Evento from '../evento/Evento';
import Expo from '../expo/Expo';
import Sala from '../conferencias/Sala';
import Registro from '../registro/Registro';
import AvisoPrivacidad from '../evento/AvisoPrivacidad';

import {Switch, Route, useLocation } from 'react-router-dom';

import Admin from '../admin/Admin';
import VisualizarPreguntas from '../admin/VisualizarPreguntas';
import Login from '../registro/Login';
import CerrarSesion from '../sesion/CerrarSesion';
import Perfil from '../sesion/Perfil';
import Error from "../auth/Error";

import PayPal from '../../utils/PayPal';
import PayPalIndependiente from '../../utils/PayPalIndependiente';

import Inauguracion from '../revivelo/Inauguracion';
import ConferenciaUno from '../revivelo/ConferenciaUno';

import Conferencia from '../en-vivo/Conferencia';
import Proximo from '../en-vivo/Proximo';

import { AuthContext } from '../../utils/AuthContext';

import '../../assets/css/global.css';
import Ponentes from '../evento/Ponentes';
import Patrocinadores from '../evento/Patrocinadores';



export const Master = () => {

  const [idioma, setIdioma] = useState('es');
  const [login, setLogin] = useState(false);

  const secciones = [
    {componente:<Evento />, url:'/'},
    {componente:<Conferencia setLogin={setLogin} />, url:'/en-vivo'},
    {componente:<Registro />, url:'/registro'},
    {componente:<Ponentes />, url:'/ponentes'},
    {componente:<Patrocinadores />, url:'/canacem'}
  ];
  
  const secciones_auth = [
    //{componente:Sala, url:'/conferencias'},
    //{componente:Perfil, url:'/perfil'},
    //{componente:Login, url:'/login'},
    //{componente:Perfil, url:'/cuenta'},
    //{componente:Conferencia, url:'/en-vivo'},
    
    {componente:Admin, url:'/admin'},
    {componente:VisualizarPreguntas, url:'/visualizar-preguntas'},
    {componente:CerrarSesion, url:'/cerrar-sesion'}
  ];

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <Route path={seccion.url} exact render={(props)=>{
          return(
            seccion.componente
          );
        }} />
      );
    });

    let visualizar_secciones_auth = secciones_auth.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} idioma={idioma} />
      );
    });

    const location = useLocation();

    useEffect(() => {
      if(location.hash === ''){
          scroller.scrollTo('header');
      }else{
          scroller.scrollTo(location.hash);
      }
      
  });

  return(
    <>
    <AuthContext >
      <Element name="header" />
      <Header setIdioma={setIdioma} idioma={idioma} mostrar_login={login} setMostrarLogin={setLogin} />
    <Switch>
      {visualizar_secciones}
      {visualizar_secciones_auth}
      <Route path='/*' exact component={Error} idioma={idioma} />
    </Switch>
    <Footer />
    </AuthContext>
    </>
  );
}