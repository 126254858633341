import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { Row, Col, Spin } from 'antd';

import '../../assets/css/paypal.css';

import logo_candado from '../../assets/images/sitio/registro/icono_candado.png';
import logo_user from '../../assets/images/sitio/registro/icono_user.png';
import banner_registro from '../../assets/images/sitio/registro/banner_registro.jpg';

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

const RegistroCompleto = (props) => {

  const {datos} = props;

  const [cargar_pantalla, setCargarPantalla] = useState(false);


  return (

    <>
      <Spin spinning={cargar_pantalla} delay={0}>
        <br /><br />
        

              <Row>
              <Col span={24}>
              <br /><br /><br />
                <img src={banner_registro  } style={{ width: '100%' }} />
            </Col>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 14, offset: 5 }} >
                  <Row>
                  <Col span={24}>
                    <br /><br />
                    </Col>
                    <Col span={24}>
                    <p className="contenedor_registro_exitoso registro_exitoso">
                     
                        <strong>REGISTRO EXITOSO</strong>
                      
                      </p>
                    </Col>
                    <Col span={24}>
                      
                      <p className="texto_registro_exitoso">
                        En breve recibirá un correo de confirmación a la siguiente dirección:
              <br />
                        <strong>{datos.correo}</strong>
                      </p>
                      
                      
                      <p className="texto_registro_exitoso color_azul">
                        Si no encuentra el mail confirmación en su bandeja de entrada, es posible el mensaje
                        haya sido enviado a la carpeta de SPAM / NO DESEADOS.
            </p>
            
<br />
                     
                      <p className="registro_exitoso_sin_linea">
                        <span className="registro_exitoso_sin_linea">INSTRUCCIONES DE ACCESO</span></p>
                        <br />
                      <Row justify="center">
                        <Col span={1}>
                          <p className="numero_instruccion color_azul">1.</p>
                        </Col>
                        <Col span={19}>
                          <p className="texto_instruccion">
                           
                                <>Ingresa a </>
                              
                            <Link to="/en-vivo" className="color_azul"><strong>premio.canacem.org.mx/en-vivo</strong></Link></p>
                        </Col>
                        <Col span={24}></Col>
                        <Col span={1}>
                          <p className="numero_instruccion color_azul">2.</p>
                        </Col>
                        <Col span={19}>
                          
                            <p className="texto_instruccion">
                            Copia tu <strong>usuario (correo electrónico)</strong> y pégalo en la casilla correspondiente.</p>
                           
                        </Col>
                        <Col span={24}></Col>
                        <Col span={1}>
                          <p className="numero_instruccion color_azul">3.</p>
                        </Col>
                        <Col span={19}>
                          
                            <p className="texto_instruccion">
                            Repetir el paso anterior, con tu <strong>primer apellido (en minúsculas y sin acentos)</strong>.</p>
                            
                        </Col>
                        <Col span={24}></Col>
                        <Col span={1}>
                          <p className="numero_instruccion color_azul">4.</p>
                        </Col>
                        <Col span={19}>
                          
                            <p className="texto_instruccion">
                            Dar clic en <strong>ingresar</strong> y disfrutar del contenido.
                </p>
                
                        </Col>
                      </Row>


                      <Row align="middle">
                        <Col span={24} offset={0}>
                            <br />
                         
                          <p className="registro_exitoso_sin_linea">
                            <span>DATOS DE ACCESO</span></p>
                          
                          </Col>
                          <Col xs={{span:3, offset:3}} md={{span:3, offset:6}}>
                            <img src={logo_user} className="icono_registro" />
                          </Col>
                          <Col xs={{span:15, offset:0}} md={{span:13, offset:0}}>
                          
                            <p className="texto_acceso">
                            Tu usuario (correo electrónico): 
                            <br />
                            <strong>{datos.correo}</strong>
                          </p>
                          </Col>
                          <Col span={24}></Col>
                          <Col xs={{span:3, offset:3}} md={{span:3, offset:6}}>
                            <img src={logo_candado} className="icono_registro" />
                          </Col>
                          <Col xs={{span:15, offset:0}} md={{span:13, offset:0}}>
                         
                         <p className="texto_acceso">
                            Tu primer apellido:
                            <br />
                            <strong>{datos.pass} </strong>
                          </p>
                         
                        
                        </Col>
                      </Row>
                    </Col>
                    
                    <Col span={24}>
                      <br />
                      
                        <p className="nota_registro_exitoso color_gris">
                        Nota: La transmisión se habilitará 30 min antes del evento
            </p>
           
                    </Col>

                  </Row>
                </Col>
              </Row>
              


        
        <br /><br /><br />
      </Spin>
    </>



  );

}

export default RegistroCompleto;