import React from 'react';
import { Row, Col, Collapse } from 'antd';
import '../../assets/css/aviso_privacidad.css';

const { Panel } = Collapse;

const AvisoPrivacidad = (props) => {

    const {idioma} = props;

    return(
<Row>
    <Col span={22} offset={1}>
        <br /><br /><br /><br />
        <p>
        {idioma === 'es' ?
        <p class="titulo_pagina_aviso">
        Privacy Policy</p>
        :
        <p class="titulo_pagina_aviso">
        Privacy Policy</p>
    }
        </p>
        {idioma === 'es' ?
<>
<p class="titulo_aviso">
YOUR PERSONAL DATA IS IMPORTANT TO US
</p>
<p class="texto_aviso">
Business Sweden (hereinafter referred to as “Business Sweden”, “we”, “our” and “us”) takes privacy and integrity very seriously, and we only process personal data relating to an identified or identifiable natural person in accordance with applicable data protection legislations and this privacy policy.
</p>
<p class="titulo_aviso">
PURPOSE OF THIS POLICY
</p>
<p class="texto_aviso">
The purpose of this privacy policy is to provide you with information about how we use, capture and share your personal data. This privacy policy sets the data protection and security standards within Business Sweden and applies worldwide, including all of our subsidiaries and branches and is based on globally accepted principles on data protection.
<br />
Our employees are obliged to adhere to this privacy policy as well as observe applicable local data protection legislations.
</p>
        <Collapse defaultActiveKey={['1']} >
        <Panel header={<span class="subtitulo_aviso">Data Controller</span>} key="1">
      <p class="texto_aviso">
      Business Sweden, corporate identity no. 262000-1194, with its registered office in Stockholm, Sweden, postal address; Box 240, SE-101 24 Stockholm, Sweden, and all its affiliates and subsidiaries, privacy@business-sweden.se, is the data controller of Business Sweden’s processing of your personal data.
<br />
Business Sweden is thus responsible to ensure that your personal data is processed in a safe manner and in accordance with applicable data protection legislation. 
<br />
If you have any questions or wish to receive further information on our processing of your personal data, please contact us at <a href="mailto:privacy@business-sweden.se">privacy@business-sweden.se</a>, or by letter to the above-mentioned postal address.
      </p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Where your personal data come from</span>} key="2">
      <p class="texto_aviso">
      Most personal data that we process comes directly from you. That could be data you provide to us when applying for one of our services, downloading a report from our web site, communicating with us by e-mail, signing up for one of our seminars, events or delegation trip.
<br />
We may also collect personal data about you as a result of you visiting our web site, for example, we save visitor traffic and communication data. An example of such personal data is, your IP-address, type of device, operating system and browser.
<br />
If you do not provide us with the personal data we ask for, we may not be able to provide our consultancy or other services to you. We will clearly mark when it is mandatory to provide certain personal data.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Processing reliability and security</span>} key="3">
      <p class="texto_aviso">
      Business Sweden only collects and processes personal data about you if it is necessary to meet legal obligations, fulfil an agreement with you, or if it is in our legitimate interest or if you have explicitly given your consent to our processing.
<br />
Business Sweden is also committed to ensuring that personal data is secure regardless whether the data is processed electronically or in paper format. To prevent unauthorized access, disclosure or destruction Business Sweden has put in place appropriate physical, electronic and managerial procedures to safeguard and secure your personal data.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">What personal data we collect</span>} key="4">
      <p class="texto_aviso">
      Business Sweden collects the following personal data of current and prospective clients and their representatives, and job candidates, individuals who agree to participate in market research, registered users and website users (collectively referred to in this policy as “Individuals”).
<br />
Business Sweden collects contact details such as name, address, telephone and fax numbers and e-mail address of Individuals who request Business Sweden´s consultancy and other services, information materials or reports, Individuals seeking employment or engagement with Business Sweden and Individuals being a contact person at Business Sweden’s service providers, vendors and customers.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Purpose and legal basis</span>} key="5">
      <p class="texto_aviso">
      Business Sweden will process personal data for the following purposes and based on the following legal basis:
        <br />
        <strong>
Providing consultancy service, reports and other materials available for downloading or purchasing on our web site and answering questions.
</strong>
<br />

<span style={{fontStyle:'italic'}}>
Legal basis: Fulfillment of an agreement with you. Our legitimate interest in fulfilling our assignment with your employer and our mission. Consent through an unambiguous affirmative action.
</span>
<br />
<strong>
Invitations to and administration of various events, seminars and delegation trips independently or in corporation with our partners: 
</strong>
<ul>
<li>Creating participant lists</li>
<li>Communicating with the participants, for example via confirmation of applications, questions or evaluations</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>Legal basis: Fulfillment of an agreement with you and/or consent through an unambiguous affirmative action, for example when you sign up for an event or a delegation trip.</span>
<br />
<strong>
Customer surveys to monitor the impacts of our efforts, such as satisfaction surveys and specific investigations, for example after the implementation of an event.
</strong>
<br />

<span style={{fontStyle:'italic'}}>Legal basis: Consent through an unambiguous affirmative action, for example when you chose to participate in a survey. </span>
<br />

<strong>Marketing purposes</strong>
<br />
<ul>
<li>Direct marketing, follow up by e-mail, telephone or mail with Individuals who have requested informational materials or down loaded reports from our web site, to provide further information and send promotional e-mail or mail about Business Sweden´s consultancy services as well as other information we assess you may find interesting</li>
<li>Marketing optimization, including, market research, analyzing and improving marketing campaigns and profiling internet users</li>
</ul>

<br />
<span style={{fontStyle:'italic'}}>
Legal basis: Consent through an unambiguous affirmative action, for example when you chose to participate in a survey. 
</span>
<br />
<strong>
To send communication via different digital channels to registered users based on:
</strong>
<br />
<ul>
<li>Explicit interest</li>
<li>Browsing history </li>
<li>Downloading of reports</li>
<li>Participating in events</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: Fulfillment of an agreement with you. Consent through an unambiguous affirmative action.
</span>
<br />
<strong>
To manage your user account:
</strong>
<br />
<ul>
<li>Create and administrate your user account</li>
<li>Send e-mails to you based on how you use our services</li>
<li>Deliver the services you have requested</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: Fulfillment of an agreement with you</span>
<br />
<strong>
Job candidates
</strong>
<ul>
    <li>To evaluate and recruit job candidates</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: To take steps at the request of your prior to entering into an agreement.
</span>
<br />
<strong>
Accounting 
</strong>
<ul>
    <li>We may also use your personal data for our accounting.</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: To fulfil our legal obligations.
</span>


</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Who we may share your personal data with</span>} key="6">
      <p class="texto_aviso">
      As a general rule, we do not share your personal data with third parties. However, we may share your personal data to certain trusted third parties to be able to provide our consultancy and other services, including:
<br />
<ul>
<li>Third parties performing services on our behalf, inter alia, authorized vendors, agents and representatives;</li>
<li>Suppliers to whom we outsource certain IT support services, such as IT infrastructure suppliers, document and workflow management systems and other similar systems;</li>
<li>Partners, collaborators, customers and service providers involved in hosting and arranging events, seminars and delegation trips;</li>

</ul>

<br />
All transfers of personal data will be in accordance with law and will only be used for the purposes it was collected for. We ensure that your personal data is processed in a lawful and correct way where the data is transferred outside the EU/EEA-area. All transfer outside the EU/EEA-area will be made in accordance with written agreements in the form of Standard Contractual Clauses.
<br />
Business Sweden may place ads on its websites, on publishers' websites or within e-mail content, either directly through the publisher or via a third party. For Individuals interacting with the advertising content, ad server companies may collect information about Individuals such as domain type, IP address, clickstream data. This information is not identifiable by Individuals’ personal data other than their IP address.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">How long we kill keep your personal data</span>} key="7">
      <p class="texto_aviso">
      Personal data collected for the purpose to provide consultancy and other services will be stored for ten years after the assignment was fulfilled or for such longer time as motivated based on the nature of the services or customer relation.
<br />
Personal data collected for the purpose to administrate our contractual relationship with our suppliers, will be stored for ten years after the contractual relation has ended unless we are required under law (book keeping or account legislation) to store the information for a longer period.
<br />
Personal data collected in connection with a recruitment process will be stored for as long as the recruitment process is open and thereafter for as long as a job applicant not being employed may take legal actions against us in respect of the recruitment process.
<br />
Personal data collected for marketing purposes and the purpose to provide you with invitations to seminars, events and delegation trips will be stored until you deregister or unsubscribe for such mailings.
<br />
Personal Data relating to registered users, will be deleted when the user account has been inactive for two years.
<br />
Personal data collected in connection with you attending a seminar, event or delegation trip hosted by us, will be deleted as soon as the seminar, event or delegation trip has been hold.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Your rights</span>} key="8">
      <p class="texto_aviso">
      According to the EU:s general data protection regulation, you as an Individual have the following rights in relation to the personal data we process about you.
<br />
<strong>Access</strong> to your personal data: You have the right to access your personal data and receive information on how it is processed.

<br />
<strong>Rectification, erasure and restriction:</strong> You are entitled to rectification of the personal data you consider to be incorrect. In certain cases, you are entitled to have your personal data deleted. This does not apply if your personal data still is required, for example due to legal requirements or if the processing may be based on any other legal ground. You have the right to request that the processing of personal data is restricted to a shorter time when we investigate potential errors.

<br />
<strong>Withdraw consent:</strong> If you have consented to a certain type of processing, you have the right to withdraw your consent.

<br />
<strong>Objection to balancing of interests:</strong> When the processing is based on balancing of interests you have the right, at any time, to object to such processing.

<br />
<strong>Objection to direct marketing:</strong> You may, at any time, object to processing of your personal data for direct marketing purposes. Upon such objection, the personal data shall not be processed for such purpose anymore.

<br />
<strong>Data portability:</strong> You are entitled to data portability, which means that you may, under certain circumstances, have the right to receive such personal data that you have provided to us, for the purpose of transferring such personal data to another controller.
<br />
<strong>Complaint:</strong> You also have the right to file a complaint to your national supervisory authority (Sw. Datainspektionen) if you believe that our processing of your personal data does not meet the requirements of the EU Data Protection Regulation.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Request for information and contact</span>} key="9">
      <p class="texto_aviso">
      If you have any questions or complaints regarding our processing of your personal data or if you wish to exercise any of your statutory rights, please contact us on privacy@business-sweden.se or
      <br /><br />
Business Sweden<br />
Box 240<br />
101 24 Stockholm<br />
SWEDEN
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Changes to this privacy policy</span>} key="10">
      <p class="texto_aviso">
      We may make additions and alterations to this privacy policy. We will publish the updated notice on our website. If the changes are significant we will provide you with an updated notice in advance of the change taking effect. We encourage you to review the content of this notice regularly.
      </p>
    </Panel>
  </Collapse>
  </>
        :
        <>
<p class="titulo_aviso">
YOUR PERSONAL DATA IS IMPORTANT TO US
</p>
<p class="texto_aviso">
Business Sweden (hereinafter referred to as “Business Sweden”, “we”, “our” and “us”) takes privacy and integrity very seriously, and we only process personal data relating to an identified or identifiable natural person in accordance with applicable data protection legislations and this privacy policy.
</p>
<p class="titulo_aviso">
PURPOSE OF THIS POLICY
</p>
<p class="texto_aviso">
The purpose of this privacy policy is to provide you with information about how we use, capture and share your personal data. This privacy policy sets the data protection and security standards within Business Sweden and applies worldwide, including all of our subsidiaries and branches and is based on globally accepted principles on data protection.
<br />
Our employees are obliged to adhere to this privacy policy as well as observe applicable local data protection legislations.
</p>
        <Collapse defaultActiveKey={['1']} >
        <Panel header={<span class="subtitulo_aviso">Data Controller</span>} key="1">
      <p class="texto_aviso">
      Business Sweden, corporate identity no. 262000-1194, with its registered office in Stockholm, Sweden, postal address; Box 240, SE-101 24 Stockholm, Sweden, and all its affiliates and subsidiaries, privacy@business-sweden.se, is the data controller of Business Sweden’s processing of your personal data.
<br />
Business Sweden is thus responsible to ensure that your personal data is processed in a safe manner and in accordance with applicable data protection legislation. 
<br />
If you have any questions or wish to receive further information on our processing of your personal data, please contact us at <a href="mailto:privacy@business-sweden.se">privacy@business-sweden.se</a>, or by letter to the above-mentioned postal address.
      </p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Where your personal data come from</span>} key="2">
      <p class="texto_aviso">
      Most personal data that we process comes directly from you. That could be data you provide to us when applying for one of our services, downloading a report from our web site, communicating with us by e-mail, signing up for one of our seminars, events or delegation trip.
<br />
We may also collect personal data about you as a result of you visiting our web site, for example, we save visitor traffic and communication data. An example of such personal data is, your IP-address, type of device, operating system and browser.
<br />
If you do not provide us with the personal data we ask for, we may not be able to provide our consultancy or other services to you. We will clearly mark when it is mandatory to provide certain personal data.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Processing reliability and security</span>} key="3">
      <p class="texto_aviso">
      Business Sweden only collects and processes personal data about you if it is necessary to meet legal obligations, fulfil an agreement with you, or if it is in our legitimate interest or if you have explicitly given your consent to our processing.
<br />
Business Sweden is also committed to ensuring that personal data is secure regardless whether the data is processed electronically or in paper format. To prevent unauthorized access, disclosure or destruction Business Sweden has put in place appropriate physical, electronic and managerial procedures to safeguard and secure your personal data.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">What personal data we collect</span>} key="4">
      <p class="texto_aviso">
      Business Sweden collects the following personal data of current and prospective clients and their representatives, and job candidates, individuals who agree to participate in market research, registered users and website users (collectively referred to in this policy as “Individuals”).
<br />
Business Sweden collects contact details such as name, address, telephone and fax numbers and e-mail address of Individuals who request Business Sweden´s consultancy and other services, information materials or reports, Individuals seeking employment or engagement with Business Sweden and Individuals being a contact person at Business Sweden’s service providers, vendors and customers.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Data Controller</span>} key="5">
      <p class="texto_aviso">
      Business Sweden will process personal data for the following purposes and based on the following legal basis:
        <br />
        <strong>
Providing consultancy service, reports and other materials available for downloading or purchasing on our web site and answering questions.
</strong>
<br />

<span style={{fontStyle:'italic'}}>
Legal basis: Fulfillment of an agreement with you. Our legitimate interest in fulfilling our assignment with your employer and our mission. Consent through an unambiguous affirmative action.
</span>
<br />
<strong>
Invitations to and administration of various events, seminars and delegation trips independently or in corporation with our partners: 
</strong>
<ul>
<li>Creating participant lists</li>
<li>Communicating with the participants, for example via confirmation of applications, questions or evaluations</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>Legal basis: Fulfillment of an agreement with you and/or consent through an unambiguous affirmative action, for example when you sign up for an event or a delegation trip.</span>
<br />
<strong>
Customer surveys to monitor the impacts of our efforts, such as satisfaction surveys and specific investigations, for example after the implementation of an event.
</strong>
<br />

<span style={{fontStyle:'italic'}}>Legal basis: Consent through an unambiguous affirmative action, for example when you chose to participate in a survey. </span>
<br />

<strong>Marketing purposes</strong>
<br />
<ul>
<li>Direct marketing, follow up by e-mail, telephone or mail with Individuals who have requested informational materials or down loaded reports from our web site, to provide further information and send promotional e-mail or mail about Business Sweden´s consultancy services as well as other information we assess you may find interesting</li>
<li>Marketing optimization, including, market research, analyzing and improving marketing campaigns and profiling internet users</li>
</ul>

<br />
<span style={{fontStyle:'italic'}}>
Legal basis: Consent through an unambiguous affirmative action, for example when you chose to participate in a survey. 
</span>
<br />
<strong>
To send communication via different digital channels to registered users based on:
</strong>
<br />
<ul>
<li>Explicit interest</li>
<li>Browsing history </li>
<li>Downloading of reports</li>
<li>Participating in events</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: Fulfillment of an agreement with you. Consent through an unambiguous affirmative action.
</span>
<br />
<strong>
To manage your user account:
</strong>
<br />
<ul>
<li>Create and administrate your user account</li>
<li>Send e-mails to you based on how you use our services</li>
<li>Deliver the services you have requested</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: Fulfillment of an agreement with you</span>
<br />
<strong>
Job candidates
</strong>
<ul>
    <li>To evaluate and recruit job candidates</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: To take steps at the request of your prior to entering into an agreement.
</span>
<br />
<strong>
Accounting 
</strong>
<ul>
    <li>We may also use your personal data for our accounting.</li>
</ul>
<br />
<span style={{fontStyle:'italic'}}>
Legal basis: To fulfil our legal obligations.
</span>


</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Who we may share your personal data with</span>} key="6">
      <p class="texto_aviso">
      As a general rule, we do not share your personal data with third parties. However, we may share your personal data to certain trusted third parties to be able to provide our consultancy and other services, including:
<br />
<ul>
<li>Third parties performing services on our behalf, inter alia, authorized vendors, agents and representatives;</li>
<li>Suppliers to whom we outsource certain IT support services, such as IT infrastructure suppliers, document and workflow management systems and other similar systems;</li>
<li>Partners, collaborators, customers and service providers involved in hosting and arranging events, seminars and delegation trips;</li>

</ul>

<br />
All transfers of personal data will be in accordance with law and will only be used for the purposes it was collected for. We ensure that your personal data is processed in a lawful and correct way where the data is transferred outside the EU/EEA-area. All transfer outside the EU/EEA-area will be made in accordance with written agreements in the form of Standard Contractual Clauses.
<br />
Business Sweden may place ads on its websites, on publishers' websites or within e-mail content, either directly through the publisher or via a third party. For Individuals interacting with the advertising content, ad server companies may collect information about Individuals such as domain type, IP address, clickstream data. This information is not identifiable by Individuals’ personal data other than their IP address.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">How long we kill keep your personal data</span>} key="7">
      <p class="texto_aviso">
      Personal data collected for the purpose to provide consultancy and other services will be stored for ten years after the assignment was fulfilled or for such longer time as motivated based on the nature of the services or customer relation.
<br />
Personal data collected for the purpose to administrate our contractual relationship with our suppliers, will be stored for ten years after the contractual relation has ended unless we are required under law (book keeping or account legislation) to store the information for a longer period.
<br />
Personal data collected in connection with a recruitment process will be stored for as long as the recruitment process is open and thereafter for as long as a job applicant not being employed may take legal actions against us in respect of the recruitment process.
<br />
Personal data collected for marketing purposes and the purpose to provide you with invitations to seminars, events and delegation trips will be stored until you deregister or unsubscribe for such mailings.
<br />
Personal Data relating to registered users, will be deleted when the user account has been inactive for two years.
<br />
Personal data collected in connection with you attending a seminar, event or delegation trip hosted by us, will be deleted as soon as the seminar, event or delegation trip has been hold.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Your rights</span>} key="8">
      <p class="texto_aviso">
      According to the EU:s general data protection regulation, you as an Individual have the following rights in relation to the personal data we process about you.
<br />
<strong>Access</strong> to your personal data: You have the right to access your personal data and receive information on how it is processed.

<br />
<strong>Rectification, erasure and restriction:</strong> You are entitled to rectification of the personal data you consider to be incorrect. In certain cases, you are entitled to have your personal data deleted. This does not apply if your personal data still is required, for example due to legal requirements or if the processing may be based on any other legal ground. You have the right to request that the processing of personal data is restricted to a shorter time when we investigate potential errors.

<br />
<strong>Withdraw consent:</strong> If you have consented to a certain type of processing, you have the right to withdraw your consent.

<br />
<strong>Objection to balancing of interests:</strong> When the processing is based on balancing of interests you have the right, at any time, to object to such processing.

<br />
<strong>Objection to direct marketing:</strong> You may, at any time, object to processing of your personal data for direct marketing purposes. Upon such objection, the personal data shall not be processed for such purpose anymore.

<br />
<strong>Data portability:</strong> You are entitled to data portability, which means that you may, under certain circumstances, have the right to receive such personal data that you have provided to us, for the purpose of transferring such personal data to another controller.
<br />
<strong>Complaint:</strong> You also have the right to file a complaint to your national supervisory authority (Sw. Datainspektionen) if you believe that our processing of your personal data does not meet the requirements of the EU Data Protection Regulation.
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Request for information and contact</span>} key="9">
      <p class="texto_aviso">
      If you have any questions or complaints regarding our processing of your personal data or if you wish to exercise any of your statutory rights, please contact us on privacy@business-sweden.se or
      <br /><br />
Business Sweden<br />
Box 240<br />
101 24 Stockholm<br />
SWEDEN
</p>
    </Panel>

    <Panel header={<span class="subtitulo_aviso">Changes to this privacy policy</span>} key="10">
      <p class="texto_aviso">
      We may make additions and alterations to this privacy policy. We will publish the updated notice on our website. If the changes are significant we will provide you with an updated notice in advance of the change taking effect. We encourage you to review the content of this notice regularly.
      </p>
    </Panel>
  </Collapse>
  </>
    }
    <br /><br />
</Col>
</Row>
    );
}

export default AvisoPrivacidad;
