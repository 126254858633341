import firebase from 'firebase';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// Your web app's Firebase configuration

var firebaseConfig = {
    apiKey: "AIzaSyBoFAcb7m4-DL5BsbUzjyh4ND7Gpx5iZnU",
    authDomain: "canacem-webinars.firebaseapp.com",
    databaseURL: "https://canacem-webinars.firebaseio.com",
    projectId: "canacem-webinars",
    storageBucket: "canacem-webinars.appspot.com",
    messagingSenderId: "302502503109",
    appId: "1:302502503109:web:58a13830939be586d4c6ac",
    measurementId: "G-XVFL67EQX5"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();


class Firebase {

    app;

    constructor(){
            
        this.app = firebase;
        this.conexion = firebase.database();
        
    }


    obtener_webinars_usuario = (url, campo, valor, funct) => {

        console.log('ojal exista');
        this.conexion.ref(url).once('value', (objetos) => {

            console.log('existio');

            if (this.collectionToArr(objetos).length > 0) {

                console.log('esta');

                let objeto_buscado = this.collectionToArr(objetos).filter(objeto => {

                    console.log(objeto[campo]);
                    if (objeto[campo] === valor) {
                        return true;
                    } else {
                        return false;
                    }
                });

                console.log(objeto_buscado);

                if(objeto_buscado.length > 0){
                    return funct(objeto_buscado[0]);
                }else{
                    console.log('no enconbtro nada');
                    return funct(null);
                }
            }
            console.log('no esta');
            return funct(null);
        })
    }
    
    obtener_objeto = (url, campo, valor, funct) => {
        
        this.conexion.ref(url).once('value', (objetos) => {

            if (this.collectionToArr(objetos).length > 0) {

                let objeto_buscado = this.collectionToArr(objetos).filter(objeto => {
                    if (objeto[campo] === valor) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if(objeto_buscado.length > 0){
                    return funct(objeto_buscado[0]);
                }else{
                    const datos_objeto = {};
                    datos_objeto[campo] = valor;
                    this.escribir(url, undefined, datos_objeto, funct);
                }
            } else {
                const datos_objeto = {};
                datos_objeto[campo] = valor;
                this.escribir(url, undefined, datos_objeto, funct);
            }
        });
    }

    verificar_objeto = (url, campo, valor, funct) => {
        
        this.conexion.ref(url).once('value', (objetos) => {

            if (this.collectionToArr(objetos).length > 0) {

                let objeto_buscado = this.collectionToArr(objetos).filter(objeto => {
                    if (objeto[campo] === valor) {
                        return true;
                    } else {
                        return false;
                    }
                });


                if(objeto_buscado.length > 0){
                    funct(true);
                }else{
                    funct(false);
                }
            } else {
                funct(false);
            }
        });
    }

    escribir = (url, id, objeto, funct) => { //sendquestion || updateQuestion
        let key = '';
        if (id === undefined) {
            key = this.conexion.ref(url).push().key;
        } else {
            key = id;
        }

        console.log(objeto);

        this.conexion.ref(url).child(key).set(objeto, () => {
            objeto['key'] = key;
            return funct(objeto);
        });
    }

    obtener_objetos = (url, escuchar ,funct, apagar = false) => { //questions

        if(apagar){
            this.conexion.ref(url).off('value');
        }

        if(escuchar){
            this.conexion.ref(url).orderByChild("num_pregunta").on('value', (objetos) => {
                return funct(this.collectionToArr(objetos));
            })
        }else{
            this.conexion.ref(url).orderByChild("num_pregunta").once('value', (objetos) => {
                return funct(this.collectionToArr(objetos));
            })
        }
    }

    obtener_parametro = (url, escuchar ,funct) => {

        if(escuchar){
            this.conexion.ref(url).on('value', (parametro) => {
                return funct(parametro);
            })
        }else{
            this.conexion.ref(url).once('value', (parametro) => {
                return funct(parametro);
            })
        }
    }

    collectionToArr = (collection) => {
        var rows = [];
        if (collection && collection.val()) {

            collection.forEach((obj) => {
                let item = obj.val();
                item.key = obj.key;
                rows.push(item);
            });
        }
        return rows;
    }


}

export default Firebase;