import React, { useEffect, useContext ,useState} from "react";
import { Row, Col, Modal, Spin, message , Button, notification} from "antd";
import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';
import Live2 from '../conferencias/Live2';
import {Preguntas} from '../conferencias/Preguntas'; 
import {Estadisticas} from '../conferencias/Estadisticas';
import Backstage from "./Backstage";
import '../../assets/css/sala2.css';


const nombre_evento = 'canacem-21';

const app = new Firebase();

let key = '';

const success = () => {
    mensaje('success','Bienvenido','');
};

    const mensaje = (tipo,titulo, texto) =>{
        notification[tipo]({
            message: titulo,
            description: texto,
        });
    }

    const lives = [
        {nombre:'Estadísticas del sector y accidentes graves',url:'https://www.youtube.com/embed/G_bgRfcDjW4?autoplay=1&mute=1&rel=0&playsinline=1',dia:1,video:1, activar_preguntas:false},
        {nombre:'Protocolos de actuación ante amenazas biológicas',url:'https://www.youtube.com/embed/OikK8Z1CDss?autoplay=1&mute=1&rel=0&playsinline=1',dia:1,video:2, activar_preguntas:false},
        {nombre:'Gestión de Contratistas',url:'https://www.youtube.com/embed/WqXu0H5NcGg?autoplay=1&mute=1&rel=0&playsinline=1',dia:2,video:1, activar_preguntas:false},
        {nombre:'Cómo lograr el aprendizaje en modalidad virtual',url:'https://www.youtube.com/embed/dE8g_xJnhrU?autoplay=1&mute=1&rel=0&playsinline=1',dia:2,video:2, activar_preguntas:false},
        {nombre:'Prevención del delito',url:'https://www.youtube.com/embed/qabtQumRx_U?autoplay=1&mute=1&rel=0&playsinline=1',dia:3,video:1, activar_preguntas:false},
        {nombre:'Seguridad Vial',url:'https://www.youtube.com/embed/uN5FrIMpcE8?autoplay=1&mute=1&rel=0&playsinline=1',dia:3,video:2, activar_preguntas:false},
        {nombre:'Entrega de Premio mejora en el desempeño en seguridad',url:'https://www.youtube.com/embed/YsDRPdW2qpw?autoplay=1&mute=1&rel=0&playsinline=1',dia:4,video:1, activar_preguntas:false},
        //{nombre:'Implementación Nom-035',url:'https://www.youtube.com/embed/vRyziW5pzVs?autoplay=1&mute=1&rel=0&playsinline=1',dia:4,video:2, activar_preguntas:true},
        {nombre:'Retorno al trabajo y resiliencia',url:'https://www.youtube.com/embed/CeQOajHGRfA?autoplay=1&mute=1&rel=0&playsinline=1',dia:4,video:3, activar_preguntas:true},
    ]

const Conferencia =({history, setLogin}) =>{

    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);
    const [mostrar_login, setMostrarLogin] = useState(false);
    const [sin_login, setSinLogin] = useState(false);

    const [dia, setDia] = useState(4);
    const [ver_dia, setVerDia] = useState(4);
    const [ver_video, setVerVideo] = useState(3);

    const siguienteEtapa = (usuario_respuestas) => {

        key = usuario_respuestas.key;

        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respondio;
                app.obtener_objetos(nombre_evento + '/preguntas-vivo', false, (datos) => {
                    setPreguntas(datos.filter(pregunta => { return pregunta.dia === dia }));
                    app.obtener_objetos(nombre_evento + `/usuarios-encuesta${dia}`, true, (datos) => {
                        setEstadisticas(datos);
                        setCargando(false);
                        if (typeof existe_respuestas !== 'undefined') {
                            setEtapa(2);
                        } else {
                            setEtapa(1);
                        }
                    });
                })

                break;
            case 1:
                app.obtener_objetos(nombre_evento + `/usuarios-encuesta${dia}`, true, (datos) => {
                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
                break;
        }
    }

    const guardarRespuestas = (respuestas) => {
        console.log(datos);

        const actualizar_usuario = {
            correo: datos.correo,
            nombre: datos.nombre + ' ' + datos.ap_paterno + ' ' + datos.ap_materno,
            empresa: datos.empresa,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + `/usuarios-encuesta${dia}`, key, actualizar_usuario, siguienteEtapa);

    }

    useEffect(() => {
        if (!usuario) {
            //mensaje('warning','Advertencia','Para entrar a esta sección necesitas haber iniciado sesión.');
            setSinLogin(true);
        } else {
            app.obtener_objetos(nombre_evento + '/configuracion', false, (data) => {
                setDia(data[0].dia);
            });

            app.obtener_objeto(nombre_evento + `/conectados${dia}`, 'correo', datos.correo, ()=>{});

        }
    }, [usuario]);

    useEffect(() => {
        if(usuario){
            console.log(datos);
            //app.obtener_objeto(nombre_evento + `/usuarios-encuesta${dia}`, 'correo', datos.correo, siguienteEtapa);
            app.obtener_objetos(nombre_evento + `/preguntas-publico${dia}`, true, (data) => {
                console.log(data);
                setPreguntasPonente(data);
            },true);
        }
    }, [dia]);

    useEffect(()=>{
        if(dia !== ver_dia){
            setDia(ver_dia);
        }
        

    },[ver_dia])

    let ver_conferencias = lives.filter(dia =>{
        if(dia.dia == ver_dia && dia.video == ver_video){
          return true;
        }
        return false;
     });
    let ver_confes = ver_conferencias.map((objetos,index)=>
   { return(
    <Col span={24}>
        <Live2 url={objetos.url} urlyt={null} urlfb={null} urlln={null} webinar={nombre_evento} comentarios={preguntas_ponente} dia={dia} num_conferencia={objetos.video} setLogin={setLogin} activar_preguntas={objetos.activar_preguntas} />
    </Col>
    )
})

    return (

        <>
        <div >
            <Row align="" className="cuerpo">
                <Col xs={{span:20, offset:1}} md={{span:20, offset:1}} style={{textAlign:'left'}}>
                    <br /><br /><br /><br /><br /><br />
                </Col>
                <Col xs={{ span: 5, offset: 2 }} md={{ span: 5, offset: 2 }}  className={ver_dia === 1 ? 'dia_envivo_select' : 'dia_envivo'} onClick={() => {setVerDia(1); setVerVideo(1); }}>
                        <p className="sin_margin">
                        <strong>26 DE ABRIL</strong>
                        </p>
                    </Col>
                    <Col xs={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} className={ver_dia === 2 ? 'dia_envivo_select' : 'dia_envivo'} onClick={() => {setVerDia(2); setVerVideo(1);}}>
                        <p className="sin_margin">
                        <strong>27 DE ABRIL</strong>
                        
                        </p>
                    </Col>
                    <Col xs={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }}  className={ver_dia === 3 ? 'dia_envivo_select' : 'dia_envivo'} onClick={() => {setVerDia(3); setVerVideo(1);}}>
                        <p className="sin_margin">
                        <strong>28 DE ABRIL</strong>
                        </p>
                    </Col>
                    <Col xs={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} className={ver_dia === 4 ? 'dia_envivo_select' : 'dia_envivo'} onClick={() => {setVerDia(4); setVerVideo(1);}}>
                        <p className="sin_margin">
                        <strong>29 DE ABRIL</strong>
                        </p>
                    </Col>
                    
                    {ver_dia === 1 ?
                        <Col span={24}>
                            <Row>
                                <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 2 }}  className={ver_video === 1 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(1)}}>
                                    <p className="sin_margin">
                                        Estadísticas del sector y accidentes graves:
                                    </p>
                                </Col>
                                <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 0 }} className={ver_video === 2 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(2)}}>
                                    <p className="sin_margin">
                                        Protocolos de actuación ante amenazas biológicas:
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    :ver_dia === 2 ?
                    <Col span={24}>
                            <Row>
                                <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 2 }} className={ver_video === 1 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(1)}}>
                                    <p className="sin_margin">
                                        Gestión de Contratistas:
                                    </p>
                                </Col>
                                <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 0 }} className={ver_video === 2 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(2)}}>
                                    <p className="sin_margin">
                                        Cómo lograr el aprendizaje en modalidad virtual:
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        :ver_dia === 3 ?
                        <Col span={24}>
                                <Row>
                                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 2 }} className={ver_video === 1 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(1)}}>
                                        <p className="sin_margin">
                                            Prevención del delito
                                        </p>
                                    </Col>
                                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 0 }} className={ver_video === 2 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(2)}}>
                                        <p className="sin_margin">
                                            Seguridad Vial
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                    :
                    <Col xs={{span:24, offset:0}} md={{span:20, offset:2}} >
                    <Row align="middle" className="conferencia_envivo_row">
                        <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} className={ver_video === 1 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(1)}}>
                            <p className="sin_margin">
                                Entrega de Premio mejora en el desempeño en seguridad
                            </p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} md={{ span: 8, offset: 0 }} className={ver_video === 2 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(2)}} style={{display:'none'}}>
                            <p className="sin_margin">
                                Implementación<br /> Nom-035
                            </p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} className={ver_video === 3 ? 'conferencia_envivo_select' : 'conferencia_envivo'} onClick={() => {setVerVideo(3)}}>
                            <p className="sin_margin">
                                Retorno al trabajo y <br className="ocultar_movil" /> resiliencia
                            </p>
                        </Col>
                    </Row>
                </Col>
                    }
                     <Col span={24}>
                <br/>
            </Col>
                    {ver_confes}
            <Col span={24}>
                <br/>
            </Col>
            
            </Row>
            
            <Row align="">
            <Col span={24}>
                <br/><br/>
            </Col>
            {//usuario &&
            <>
            

            <Col span={24} style={{display:'none'}}>
            <Spin tip="Cargando..." spinning={cargando} >
            {
                    etapa === 1 ?
                        (
                            <>
                            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}} className="">
                                <p className="textos_titulos_secciones_no_olvides">ÁREA DE ENCUESTA</p>
                            </Col>
                            <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} >
                                {/*<Preguntas preguntas={preguntas} ver_video={ver_video} dia={dia} onResponse={guardarRespuestas} />*/}
                            </Col>
                            </>
                        )
                        :
                        (
                            <>
                            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}} className="">
                                <p className="textos_titulos_secciones_no_olvides">RESULTADOS DE ENCUESTA</p>
                            </Col>
                            <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} >
                                {/*<Estadisticas estadisticas={estadisticas} ver_video={ver_video} dia={dia} preguntas={preguntas} />*/}
                            </Col>
                            </>
                        )
                }
                </Spin>
                <br/><br/><br /><br />
                </Col>
                </>
}

      
            </Row>

            </div>

            <div>

        </div>

        </>
        
    );

}

export default Conferencia;

