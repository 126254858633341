import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Upload,
  Checkbox,
  Button, notification, Spin
} from 'antd';
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import RegistroCompleto from './RegistroCompleto';

import { mail_registro_confirmacion, codigo, paquete, SERVER_URL } from '../../utils/ConexionServidor';
import banner_registro from '../../assets/images/sitio/registro/banner_registro.jpg';
import banner_eng_registro from '../../assets/images/sitio/registro/banner_registro.jpg';
import boton_registro from '../../assets/images/sitio/registro/BTN_REGISTRARME.png';
import problemas_registro from '../../assets/images/sitio/registro/btn_problemasregistro.png';

import '../../assets/css/registro.css';

const { Option } = Select;

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
}

const Registro = (props) => {

   
    const [otro_medio, setOtroMedio] = useState(false);
    const [otra_planta, setOtraPlanta] = useState(false);
    const [datos_registro, setDatosRegistro] = useState([]);
    const [confirmacion, setConfirmacion] = useState(false);
    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const [empresa_elegida, setEmpresaElegida] = useState('');

    const [form] = Form.useForm();

    const ref_form = React.createRef();
    const ref_recaptcha = React.createRef();

  const elegirMedio = value => {
    console.log(value);

    if (value === 'Otro') {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }

  }

  const onFinish = async values => {

    if (ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' ') {
      //mensaje('warning', 'Recaptcha inválido','Por favor verifica tu identidad');
      //return;
    }
    values["g-recaptcha-response"] = ref_recaptcha.current.getValue();

    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
    values.password = values.ap_paterno.split('').map(letra => acentos[letra] || letra).join('').toString().toLowerCase();

    if (values.medio_enterado === 'Otro') {
      values.medio_enterado = values.otro_medio;
      delete values.otro_medio;
    }

    console.log(values);

    setCargarPantalla(true);

    if(values.empresa === 'Otra'){
      values.empresa = values.otra_empresa;
    }

    if(values.planta === 'Otro'){
      values.planta = values.otra_planta;
    }

    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          mensaje('success','Registrado','Te enviamos un correo de confirmación de registro al correo ' + data.correo);
          setDatosRegistro(data);
          setConfirmacion(true);
          break;
        case 1:
          mensaje('warning', 'Registrado','Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' + data.correo);
          setDatosRegistro(data);
          setConfirmacion(true);
          break;
        case 2:
          mensaje('warning','Advertencia', data);
          break;
        default:
          mensaje('error', 'Error de conexión','Inténtalo más tarde.');
          break;
      }
      setCargarPantalla(false);
    });

  };

  return (

    <>
      <Spin spinning={cargar_pantalla} delay={0}>
        {confirmacion ?
          <RegistroCompleto datos={datos_registro} />
          :
          <Row className="contenedor_registro">
            <Col span={24}>
              <br /><br /><br />
                <img src={banner_registro  } style={{ width: '100%' }} />
            </Col>
            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }}>
              <Form className="login-form"
                ref={ref_form}
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{
                 
                  /*
                  aceptar_terminos: true,
                  nombre: 'Martin',
                  ap_paterno: 'Rodríguez',
                  ap_materno: 'Ayala',
                  correo:'martin@thethree.com.mx',
                  password: '1234567890',
                  confirm: '1234567890',
                  empresa: 'The Three',
                  telefono: '1234567890',
                  miembro_ies: 1,
                  direccion: 'mi direccion',
                  cp: '09090',
                  ciudad: 'CDMX',
                  estado: 'CDMX',
                  pais: 'MEX',
                  medio_enterado: 'Facebook',
        
                  razon_social:'THE THREE',
                  rfc: '12345',
                  cp_factura:'5678',
                  correo_factura:'martin@thethree.com.mx',
                  cfdi:'Por definir (P01)',
                  direccion_factura: 'toda mi direccion',
        
                  numero_miembro: '12345',
                  carrera: 'Ingeniería',
                  semestre: '5to'
                  */

                }}
                onFinish={onFinish}
              >

                <Row style={{ marginLeft: '20px', marginRight: '20px' }}>

                  <Col span={24}>
                    <br />
                      <p className="titulo_registro color_gris">
                        <strong>
                        REGÍSTRATE
                        </strong>
                      </p>
                      
                  </Col>
                  
                  <Col span={24}>
                    <br />
                    
                  </Col>
                  
                  <Col span={24}>
                    <Form.Item
                      name="nombre"
                      label={<span class="color_gris">Nombre(s)</span>}
                      rules={[

                        {
                          required: true,
                          message: <span className="label_requerido_registro">Ingresa tu nombre!</span>,
                        },
                      ] }
                    >
                      <Input className="inputs_registro" />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 11 }}>
                    <Form.Item
                      name="ap_paterno"
                      label={<span class="color_gris">Apellido paterno</span>}
                      rules={
                        [

                          {
                            required: true,
                            message: <span className="label_requerido_registro">Ingresa tu apellido paterno!</span>,
                          },
                        ]}
                    >
                      <Input className="inputs_registro" />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                    <Form.Item
                      name="ap_materno"
                      label={<span class="color_gris">Apellido materno</span>}
                      rules={
                        [

                          {
                            required: true,
                            message: <span className="label_requerido_registro">Ingresa tu apellido materno!</span>,
                          },
                        ]}
                    >
                      <Input className="inputs_registro" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="correo"
                      label={<span class="color_gris">Correo electrónico</span>}
                      rules={
                        [
                          {
                            type: 'email',
                            message: '...',
                          },
                          {
                            required: true,
                            message: <span className="label_requerido_registro">Ingresa tu correo electrónico !</span>,
                          },
                        ]
                      }
                    >
                      <Input className="inputs_registro" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="empresa"
                      label={<span class="color_gris">Empresa</span>}
                      rules={
                        [

                          {
                            required: true,
                            message: <span className="label_requerido_registro">Ingresa el nombre de tu empresa!</span>,
                          },
                        ]}
                    >
                      <Select className="inputs_registro" onChange={(value)=>{console.log(value); setEmpresaElegida(value)}}>
                        <Option value="CEMEX">CEMEX</Option>
                        <Option value="GCC">GCC</Option>
                        <Option value="Cementos y concretos Moctezuma">Cementos y concretos Moctezuma</Option>
                        <Option value="Cementos Cruz Azul">Cementos Cruz Azul</Option>
                        <Option value="CYCNA">CYCNA</Option>
                        <Option value="Holcim México">Holcim México</Option>
                        <Option value="Cementos Fortaleza">Cementos Fortaleza</Option>
                        <Option value="Otra">Otra</Option>
                        </Select>
                    </Form.Item>
                  </Col>
                  {empresa_elegida === 'Otra' &&

                    <Col span={24}>
                      
                      <Form.Item
                        name="otra_empresa"
                        label={<span className="texto_label_form">¿Cuál?</span>}
                        rules={[

                          {
                            required: true,
                            message: <span className="label_requerido_registro">Ingresa el nombre de la empresa!</span>,
                          },
                        ] }
                      >
                        <Input className="inputs_registro" placeholder="" />
                      </Form.Item>
                    

                    </Col>
                  }

<Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
                    <Form.Item
                      name="planta"
                      label={<span class="color_gris">Planta</span>}
                      rules={
                        [
                          {
                            message: '...',
                          },
                          {
                            required: true,
                            message: <span className="label_requerido_registro">Ingresa tu planta!</span>,
                          },
                        ] }
                    >
                      <Select className="inputs_registro" onChange={(val)=>setOtraPlanta(val)} >

                        {empresa_elegida === 'CEMEX' ? 
                          <>
                            <Option value="Atotonilco">Atotonilco</Option>
                            <Option value="Barrientos">Barrientos</Option>
                            <Option value="Campana">Campana</Option>
                            <Option value="Ciudad Valles">Ciudad Valles</Option>
                            <Option value="Guadalajara">Guadalajara</Option>
                            <Option value="Ensenada">Ensenada</Option>
                            <Option value="Hidalgo">Hidalgo</Option>
                            <Option value="Huichapan">Huichapan</Option>
                            <Option value="Mérida">Mérida</Option>
                            <Option value="Monterrey">Monterrey</Option>
                            <Option value="Tamuín">Tamuín</Option>
                            <Option value="Tepeaca">Tepeaca</Option>
                            <Option value="Torreón">Torreón</Option>
                            <Option value="Yaqui">Yaqui</Option>
                            <Option value="Zapotiltic">Zapotiltic</Option>
                          </>
                          :
                          empresa_elegida === 'GCC' ?
                          <>
                            <Option value="Ciudad Juárez">Ciudad Juárez</Option>
                            <Option value="Chihuahua">Chihuahua</Option>
                            <Option value="Samalayuca">Samalayuca</Option>
                          </>
                          :
                          empresa_elegida === 'Cementos y concretos Moctezuma' ?
                          <>
                            <Option value="Apazapan">Apazapan</Option>
                            <Option value="Cerritos">Cerritos</Option>
                            <Option value="Tepetzingo">Tepetzingo</Option>
                          </>
                          :
                          empresa_elegida === 'Cementos Cruz Azul' ?
                          <>
                            <Option value="Cruz Azul, Hidalgo">Cruz Azul, Hidalgo</Option>
                            <Option value="Lagunas">Lagunas</Option>
                          </>
                          :
                          empresa_elegida === 'CYCNA' ?
                          <>
                          <Option value="Aguascalientes">Aguascalientes</Option>
                          <Option value="Oriente">Oriente</Option>
                          </>
                          :
                          empresa_elegida === 'Holcim México' ?
                          <>
                            <Option value="Acapulco">Acapulco</Option>
                            <Option value="Apaxco">Apaxco</Option>
                            <Option value="Hermosillo">Hermosillo</Option>
                            <Option value="Macuspana">Macuspana</Option>
                            <Option value="Orizaba">Orizaba</Option>
                            <Option value="Ramos Arizpe">Ramos Arizpe</Option>
                            <Option value="Tecomán">Tecomán</Option>
                          </>
                          :
                          empresa_elegida === 'Cementos Fortaleza' &&
                          <>
                          <Option value="El Palmar">El Palmar</Option>
                          <Option value="Tula">Tula</Option>
                          <Option value="Vito">Vito</Option>
                          </>
                        }

                        <Option value="Concreto">Concreto</Option>
                        <Option value="Otro">Otro</Option>
                        
                      </Select>
                    </Form.Item>
                  </Col>
                    <Col span={24}>
                    {otra_planta === 'Otro' &&
                      (
                        <Form.Item
                          name="otra_planta"
                          label={<span className="texto_label_form">¿Cuál?</span>}
                          rules={[

                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa la planta!</span>,
                            },
                          ] }
                        >
                          <Input className="inputs_registro" placeholder="" />
                        </Form.Item>
                      )
                    }
                  </Col>

                  

                  
                  <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
                    <Form.Item
                      name="puesto"
                      label={<span class="color_gris">Puesto</span>}
                      rules={
                        [
                          {
                            message: '...',
                          },
                          {
                            required: true,
                            message: <span className="label_requerido_registro">Ingresa tu puesto!</span>,
                          },
                        ] }
                    >
                      <Input className="inputs_registro" />
                    </Form.Item>
                  </Col>

                  <Col span={24}></Col>

                  <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
                    <Form.Item
                      name="medio_enterado"
                      label={<span className="color_gris">¿Por qué medio te enteraste?</span>
                    }
                      rules={ [
                        {
                          required: true,
                          message: <span className="label_requerido_registro">Elige el medio por el que te enteraste!</span>,
                        },
                      ] }
                    >
                      <Select onChange={elegirMedio} >
                        <Option value="Facebook">Facebook</Option>
                        <Option value="Twitter">Twitter</Option>
                        <Option value="Instagram">Instagram</Option>
                        
                        <Option value="Correo electrónico">Correo electrónico</Option> 
                        
                        <Option value="Whatsapp">Whatsapp</Option>
                       
                        <Option value="Otro">Otro</Option>
                     
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    {otro_medio &&
                      (
                        <Form.Item
                          name="otro_medio"
                          label={<span className="texto_label_form">¿Cuál?</span>}
                          rules={[

                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa el medio por el que te enteraste!</span>,
                            },
                          ] }
                        >
                          <Input className="inputs_registro" placeholder="" />
                        </Form.Item>
                      )
                    }
                  </Col>

                  

                  


                  <Col span={24} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name="aceptar_terminos"
                      valuePropName="checked"
                      rules={
                        [
                          {
                            validator: (_, value) =>
                              value ? Promise.resolve() : Promise.reject(<span className="label_requerido_registro">Acepta el aviso de privacidad</span>),
                          },
                        ] }
                    >
                      <Checkbox>
                        
                          <span className="texto_label_form">He leido y acepto el <strong><a target="_blank" href="https://canacem.org.mx/aviso-de-privacidad/">aviso de privacidad.</a></strong></span>
                         

                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ display: 'none' }}>
                    <ReCAPTCHA ref={ref_recaptcha}
                      sitekey="6Lf1g6gaAAAAAJ6wUIfaEmbGISttYmT-yZXyCugG"
                    />
                  </Col>

                  
                </Row>

              </Form>
            </Col>
            
            <Col xs={{span:14, offset:5}} md={{span:8, offset:8}}>
            <Form className="login-form"
                ref={ref_form}
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{

                }}
                onFinish={onFinish}
            >
<Form.Item>
                      <br />

                      <p style={{ textAlign: 'center' }}>
                        <button style={{ border: 'none', backgroundColor:'#ffffff', cursor:'pointer' }} htmlType="submit">
                            <img class="img_boton_registro" src={boton_registro} />
                        </button>
                      </p>

                    </Form.Item>

                </Form>

            </Col>
            <Col span={24}>
              <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20Canacem.">
                <img class="problemas_boton_registro" src={problemas_registro} />
                <br /><br />
              </a>
            </Col>

          </Row>
        }
      </Spin>
    </>

  );
};

export default withRouter(Registro);
