import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/ponentes.css';

import ponente_1 from '../../assets/images/sitio/ponentes/images/FELIPE_olivares.png';
import ponente_2 from '../../assets/images/sitio/ponentes/images/ILEANAruiz.png';
import ponente_3 from '../../assets/images/sitio/ponentes/images/RAUL_ROJAS.png';
import ponente_4 from '../../assets/images/sitio/ponentes/images/HECTOR_MIGUEL.png';
import ponente_5 from '../../assets/images/sitio/ponentes/images/IRMA_SONIA_VENTURA.png';
import ponente_6 from '../../assets/images/sitio/ponentes/images/OMAR_TENORIO.png';
import ponente_7 from '../../assets/images/sitio/ponentes/images/SILVIA_ROJAS.png';


const ponentes = [
  {img:ponente_1,url:'/ponentes#felipe-olivares',alt:'felipe-olivares',nombre:'Felipe Olivares'},
  {img:ponente_2,url:'/ponentes#ileana-ruiz',alt:'ileana-ruiz',nombre:'Ileana Ruíz'},
  {img:ponente_3,url:'/ponentes#raul-rojas',alt:'raul-rojas',nombre:'Raul Rojas'},
  {img:ponente_4,url:'/ponentes#hector-miguel',alt:'Hector Miguel',nombre:'Hector Miguel'},
  {img:ponente_5,url:'/ponentes#irma-sonia-ventura',alt:'Irma Sonia Ventura',nombre:'Irma Sonia Ventura'},
  {img:ponente_6,url:'/ponentes#omar-enorio',alt:'Omar Tenorio',nombre:'Omar Tenorio'},
  {img:ponente_7,url:'/ponentes#silvia-rojas',alt:'Silvia Rojas',nombre:'Silvia Rojas'},
]
const CarouselPonentes = (props) => {

  let ver_ponentes = ponentes.map((objeto) => {
    return (
      <Col xs={12} md={8}>
        <Link to={objeto.url}>
          <img src={objeto.img} alt={objeto.alt} className="img_ponentes" />
          <p className="titulo_carousel_ponente">{objeto.nombre}</p>
        </Link>
      </Col>
    )
  })

    return (
      <Row justify="center">
        {ver_ponentes}
      </Row>
    );

};

export default CarouselPonentes;