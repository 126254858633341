import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import '../../assets/css/patrocinadores.css';

import patrocinador_1 from '../../assets/images/sitio/CANACEM/cemeex.png';
import patrocinador_2 from '../../assets/images/sitio/CANACEM/cruzazul.png';
import patrocinador_3 from '../../assets/images/sitio/CANACEM/fortaleza.png';
import patrocinador_4 from '../../assets/images/sitio/CANACEM/gcc.png';
import patrocinador_5 from '../../assets/images/sitio/CANACEM/holcim.png';
import patrocinador_6 from '../../assets/images/sitio/CANACEM/moctezuma.png';
import patrocinador_7 from '../../assets/images/sitio/CANACEM/cycnanacional.png';
import patrocinador_8 from '../../assets/images/sitio/CANACEM/cycnaoriente.png';

const patrocinio =[
  {img:patrocinador_1,alt:'#cemeex',texto:'CEMEX es una compañía global productora y proveedora de materiales para la industria de la construcción. Ofrece productos de calidad y servicios confiables a clientes y comunidades en América, Europa, África, Medio Oriente y Asia.'},
  {img:patrocinador_2,alt:'#cruz-azul',texto:'La Cruz Azul es una empresa productora y comercializadora de cementos hidráulicos con cuatro plantas en la República Mexicana en los Estados de Hidalgo, Oaxaca, Puebla y Aguascalientes.'},
  {img:patrocinador_3,alt:'#fortaleza',texto:'Somos la nueva Cementera de México, la que nació tras varias décadas de poca actividad de marcas nuevas en la Industria.'},
  {img:patrocinador_4,alt:'#gcc',texto:'Grupo Cementos de Chihuahua es una empresa con un enfoque global y sustentable, fundada en 1941 es una sociedad controladora integrada verticalmente, con operaciones en México y E.U.A., cuyas subsidiarias se dedican principalmente a la fabricación y comercialización de cemento Portland gris, mortero, concreto premezclado, block de concreto, yeso, agregados y otros materiales para la construcción.'},
  {img:patrocinador_5,alt:'#holcim',texto:'Integramos la excelencia, solidez y experiencia del líder mundial en materiales para la construcción. Holcim México produce y comercializa cemento, agregados, concreto premezclado, así como otros productos y servicios para la industria de la construcción.'},
  {img:patrocinador_6,alt:'#moctezuma',texto:'A nuestra compañía la integran dos empresas: Cementos Moctezuma® y Concretos Moctezuma®.'},
]

const Patrocinadores = (props) => {

  let ver_patrocinadores = patrocinio.map((objeto, index) => {
    
    return (
      <>
      <Element name={objeto.alt} />
      
      <Col xs={24} md={12}>
      <Row justify="center">
        <Col xs={20} md={16} style={{padding:'3%'}}>
          <img src={objeto.img} alt={objeto.alt} className="img_sec_patrocinador" />
        </Col>
        <Col xs={24} className="contendor_text_patrocindor">
          <Row justify="center">
            <Col span={20}>
            <p className="texto_sec_patrocindores">
          {objeto.texto}
        </p>
            </Col>
          </Row>
        </Col>
      </Row>
      
      </Col>
      
      </>
    )
  })

    return (
      <Row>
        <Col span={24}>
        <br /><br /><br /><br />
        </Col>
        {ver_patrocinadores}
      </Row>
    );

};

export default Patrocinadores;