import React, {useState} from 'react';
import { Row, Col, Carousel, Modal } from 'antd';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import '../../assets/css/premio.css';

import img_oro from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/medallaoro_casco.png';
import img_plata from '../../assets/images/sitio/home/SECCION_PREMIO/PLATA/medallasplata_casco.png';
import img_bronce from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/medallabronce_casco.png';

import back_oro from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/banner_oro.jpg';
import back_plata from '../../assets/images/sitio/home/SECCION_PREMIO/PLATA/banner_plata.jpg';
import back_bronce from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/banner_bronce.jpg';

import oro_cemex from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/logo_cemex_bco.png';
import oro_holcim from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/logo_holcim_bco.png';
import oro_cyna from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/logo cynaoriente_bco.png';
import plata_cemex from '../../assets/images/sitio/home/SECCION_PREMIO/PLATA/logo_cemex_bco.png';
import bronce_cemex from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/logo_cemex_bco.png';
import bronce_fortaleza from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/logo_fortaleza_bco.png';
import bronce_holsim from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/logo_holcim_bco.png';
import bronce_cyna from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/logo-cementosyconcrnac_bco.png';

import cemex from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/logo_cemex.png';
import holcim from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/logo_holcim.png';
import cynaoriente from '../../assets/images/sitio/home/SECCION_PREMIO/ORO/logo_cynaoriente.png';
import fortaleza from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/logo_fortaleza.png';
import cementos from '../../assets/images/sitio/home/SECCION_PREMIO/BRONCE/logo-cementosyconcrnac.png';

const premio = [
  {back:back_oro,titulo:'ORO',texto:'3 años o más con cero accidentes ni fatalidades',logo:[oro_cemex, oro_holcim,oro_cyna],ur:'',medalla:img_oro,item:1,clase:'texto_oro'},
  {back:back_plata,titulo:'PLATA',texto:'2 años sin fatalidades ni accidentes con días perdidos ',logo:[plata_cemex],ur:'',medalla:img_plata,item:2,clase:'texto_plata'},
  {back:back_bronce,titulo:'BRONCE',texto:'1 año sin fatalidades',logo:[bronce_cemex, bronce_fortaleza, bronce_holsim,bronce_cyna],ur:'',medalla:img_bronce,item:3,clase:'texto_bronce'},
]

const ganadores = [
  {img:cemex,alt:'',nombre:['Ensenada',
  'Hidalgo, N.L.',
  'Huichapan',
  'Mérida',
  'Torreón',
  'Valles',
  'Zapotiltic',],premio:1,titulo:'ORO'},
  {img:holcim,alt:'',nombre:['Macuspana',
  'Tecomán',
  'Acapulco',
  'Hermosillo',
  'Apaxco',
  'Ramos Arizpe',],premio:1,titulo:'ORO'},
  {img:cynaoriente,alt:'',nombre:[''],premio:1,titulo:'ORO'},
  {img:cemex,alt:'',nombre:['Barrientos',
  'Yaqui'],premio:2,titulo:'PLATA'},
  {img:cemex,alt:'',nombre:['Atotonilco',
  'Tepeaca'],premio:3,titulo:'BRONCE'},
  {img:fortaleza,alt:'',nombre:['Vito'],premio:3,titulo:'BRONCE'},
  {img:holcim,alt:'',nombre:['Orizaba'],premio:3,titulo:'BRONCE'},
  {img:cementos,alt:'',nombre:['Aguascalientes'],premio:3,titulo:'BRONCE'},
]

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    
    <CaretRightFilled className={className} style={{ ...style, display: "block" , color:'#21aaf7', fontSize:'24px'}} onClick={onClick}/>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#21aaf7', fontSize:'24px' }} onClick={onClick}/>
  );
}

const Premios = (props) =>{
  const ref_carousel = React.createRef();
  const ref_carousel_dos = React.createRef();
  const [ver_dia, setVerDia] = useState(1);
  const [ver_modal, setVerModal] = useState(false);
  

  const settingslogos = {
    infinite: true,
    pauseOnHover:false,
    slidesToScroll: 1,
    autoplay:false,
    arrows:false,
    autoplaySpeed: 10000,
    speed:2000,
    dots: false,
};
const settingsGanadotes = {
  infinite: true,
  pauseOnHover:false,
  slidesToScroll: 1,
  autoplay:false,
  arrows:true,
  autoplaySpeed: 10000,
  speed:2000,
  dots: true,
  nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
};

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  const irDelante = (adelante) => {
    if(adelante){
        if(ver_dia === 1){
          setVerDia(2);
        }else if (ver_dia === 2) {
          setVerDia(3);
        } else{
          setVerDia(1);
        }
      }else{
        if(ver_dia === 1){
          setVerDia(3);
        }else if (ver_dia === 2) {
          setVerDia(1);
        } else{
          setVerDia(4);
        }
      }
  }
  let p_vista = premio.filter(objet =>{
    if(objet.item == ver_dia){
      return true;
    }
    return false;
 });
  
 let visualizar_programa = p_vista.map((objeto,index)=>{
  return(
    <>
      <Row align="middle" className="back_slider_premios" style={{ backgroundImage:`url(${objeto.back})`}}>
        <Col xs={{span:10,offset:7}} md={{span:8,offset:2}} style={{padding:'1%', textAlign:'center'}}>
        <img src={objeto.medalla} className="img_medalla"/>
        </Col>
        <Col xs={{span:20,offset:2}} md={{span:12, offset:0}} style={{padding:'2%'}}>
          
          <p className={objeto.clase}>{objeto.titulo}</p>
          <p className="texto_dec_premio">{objeto.texto}</p>
          <Row justify="center">
          {objeto.logo.map((logos,index) => {
              if(index === 0){
                return(
                <Col span={12}>
                <img src={logos} style={{width:'100%'}} />
                </Col>
                );
              }else{
                return(
                  <Col span={12}>
                  <img src={logos} style={{width:'100%'}} />
                  </Col>
                );
              }
              })}
          </Row>
          <Row justify="center">
            <Col xs={20} md={12}>
              <br /><br />
              <p className="btn_premio" onClick={()=>setVerModal(true)} >
                CONOCE A LOS GANADORES
              </p>
              <br /><br />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
});


let modal_vista = ganadores.filter(objet =>{
  if(objet.premio === ver_dia){
    return true;
  }
  return false;
});
let ver_modal_vista = modal_vista.map((objet,index)=>{
  return(
    <>
    <Row>
      <Col span={24}>
        <p className="titulo_ganador_modal">{objet.titulo}</p>
      </Col>
    <Col span={8}>
      <img src={objet.img} style={{width:'100%'}} />
    </Col>
    <Col span={16}>
    {objet.nombre.map((nombres,index) => {
              if(index === 0){
                return(
                <p className="ganadores_premio">{nombres}</p>
                );
              }else{
                return(
                  <p className="ganadores_premio">{nombres}</p>
                );
              }
              })}
    </Col>
    </Row>
    </>
  )
}

)
  
  return(
<>

    <Row justify="center" align="middle">
      <Col span={24}>
        <br />
      </Col>
      <Col span={0}>
          <CaretLeftFilled className="flecha_agenda" onClick={() => {irDelante(false);}}/>
          </Col>
     <Col span={20}>
       <Row>
       <Col xs={8} md={8} onClick={() => {setVerDia(1)}} className="ponter_select">
        <img src={img_oro} style={{width:'70%'}} />
      </Col>
      <Col xs={8} md={8} onClick={() => {setVerDia(2)}} className="ponter_select">
      <img src={img_plata} style={{width:'70%'}} />
      </Col>
      <Col xs={8} md={8} onClick={() => {setVerDia(3)}} className="ponter_select">
      <img src={img_bronce} style={{width:'70%'}} />
      </Col>
       </Row>
     </Col>
      <Col span={0}>
          <CaretRightFilled className="flecha_agenda" onClick={() => {irDelante(true);}}/>
          </Col>
      <Col span={24}>
        <br /><br />
      </Col>
    </Row>
    <Row justify="center" align="middle">
      <Col span={24}>
        <Slider ref={ref_carousel} {...settingslogos}>
          {visualizar_programa}
        </Slider>
      </Col>
      
          <Col span={24}>
            <br/>
            
          </Col>
          <Col span={24}>
       
      </Col>
        </Row>
       
        <Modal
                    style={{ backgroundColor: 'rgba(0,0,0,0)', top: '50px' }}
                    title={null}
                    footer={null}
                    closable={true}
                    visible={ver_modal !== false}
                    onCancel={() => { setVerModal(false); }}
                    bodyStyle={{ backgroundColor: 'rgba(0,0,0,0)' }}
                    
                    className="modal_premio"
                >
                  <Row justify="center" align="middle">
                  <Col span={22}>
                    <Slider ref={ref_carousel_dos} {...settingsGanadotes}>
                        {ver_modal_vista}
                        </Slider>
                    </Col>
                    </Row>
                  
                </Modal>
        </>

);
}

export default Premios;