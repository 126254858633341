import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import CarouselPatrocinadores from '../evento/CarouselPatrocinadores';
import ima from '../../../src/assets/images/sitio/home/logocanacem_header.png';
import cintillo_footer from '../../../src/assets/images/01_impacDemo_home/Thethree_ImpacCintillo.png';

import { AiFillFacebook, AiOutlineTwitter, AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";

import btn_mail from '../../assets/images/sitio/registro/icono_candado.png';
import btn_wsp from '../../assets/images/sitio/registro/icono_candado.png';
import logo_footer from '../../../src/assets/images/evento/05_footer_nordic/SemanaNordica_LogoFooter.png';
import logo_footer_en from '../../../src/assets/images/evento/05_footer_nordic/SemanaNordica_LogoFooter_eng.png';

const Footer = (props) => {
  let location = useLocation();
  console.log(location);
  const {idioma} = props;
  return(
    <>
     <Row justify="center" align="top" className="footer">
      <Col xs={14} md={6} className="contendores_footer">
        <img alt="logo" style={{width:'100%'}} src={ima}/><br/>
        <p className="link-footer" style={{paddingLeft:'12%'}}>
        Insurgentes Sur #1190 Piso 3,<br />
Tlacoquemecatl del Valle,<br />
Benito Juárez, 03200,<br />
Ciudad de México, México
</p>
        <p style={{ color:'#808285', fontSize:'10px', textAlign:'left', paddingLeft:'12%' }}>contacto@canacem.org.mx   | +52 (55) 5254 4897</p>
        </Col>
      <Col xs={15} md={4} className="contendores_footer">
        <p><strong>Legal</strong></p>
        <p>
          <a className="link-footer" target="_blank"  href="https://canacem.org.mx/aviso-de-privacidad/"><strong>Aviso de privacidad</strong></a><br/>
          <a className="link-footer" target="_blank"  href="https://canacem.org.mx/directorio/"><strong>Dirección</strong></a><br/>
          <a className="link-footer" target="_blank"  href="https://canacem.org.mx/ubicacion/"><strong>Ubicación</strong></a><br/>
          </p>
        
      </Col>
      <Col xs={15} md={4} className="contendores_footer">
        <p><strong>Empresas afiliadas</strong></p>
        <p>
          <a className="link-footer" href="https://canacem.org.mx/cemento-cruz-azul/"><strong>Cemento Cruz Azul</strong></a><br/>
          <a className="link-footer" href="https://canacem.org.mx/cementos-fortaleza/"><strong>Cementos Fortaleza</strong></a><br/>
          <a className="link-footer" href="https://canacem.org.mx/cementos-moctezuma/"><strong>Cementos Moctezuma</strong></a><br/>
          <a className="link-footer" href="https://canacem.org.mx/cemex/"><strong>CEMEX</strong></a><br/>
          <a className="link-footer" href="https://canacem.org.mx/grupo-cementos-de-chihuahua/"><strong>GCC</strong></a><br/>
          <a className="link-footer" href="https://canacem.org.mx/holcim/"><strong>Holcim</strong></a><br/>
          </p>
      </Col>
      <Col xs={15} md={4} className="contendores_footer">
        <p><strong>Redes sociales</strong></p>
        <p>
          <a className="link-footer" href="https://www.facebook.com/canacemmx"><strong>Facebook</strong></a><br/>
          <a className="link-footer" href="https://twitter.com/canacem_mx"><strong>Twitter</strong></a><br/>
          <a className="link-footer" href="https://www.youtube.com/channel/UC85lY-lKdu6QUG2KrHfCKIA"><strong>YouTube</strong></a><br/>
          <a className="link-footer" href="https://www.linkedin.com/company/canacem/"><strong>LinkedIn</strong></a><br/>
          
          </p>
      </Col>
      <Col xs={15} md={4} className="contendores_footer">
        <p><strong>Comisiones</strong></p>
        <p>
          <a className="link-footer" href="https://canacem.org.mx/comisiones/"><strong>Ingresar</strong></a><br/>
          </p>
      </Col>
      <Col span={24} style={{ padding:'3px', borderTop:'2px solid #d9d9d9' }}>
        <p style={{ fontSize:'10px' }}>© 2021 Cámara Nacional del Cemento - Todos los Derechos Reservados.</p>
        
      </Col>
    </Row>
    
    </>
  );
}

export default Footer;