import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import '../../assets/css/menu.css';
import ima from '../../../src/assets/images/sitio/logocanacem_header.png';
import { Auth } from "../../utils/AuthContext";
import { MenuOutlined } from '@ant-design/icons';


import Login from '../registro/Login';

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
    duration: 10,
  });
}

const secciones = [
  { nombre: 'INICIO', url: '/' },
  //{ nombre: 'EN VIVO', url: '/en-vivo' },
  { nombre: 'CANACEM', url: '/canacem' },
  { nombre: 'PONENTES', url: '/ponentes' },
 // { nombre: 'PONENTES', url: '/ponentes' },
  //{ nombre: 'CANACEM', url: '/canacem' }
]


const Header = (props) => {

  //const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_menu_movil, setMostrarMenuMovil] = useState(false);
  const { usuario } = useContext(Auth);
  const [mostrar_menu, setMostrarMenu] = useState(true);
  const { setIdioma, idioma, setMostrarLogin, mostrar_login } = props;

  //setIdioma('es'||'en')

  const [scrollY, setScrollY] = useState(0);
  const [scrol, setScrol] = useState(150);

  function logit() {
    setScrollY(window.pageYOffset);
    setScrol(scrollY);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });


  const cerrarMenu = () => {
      setMostrarMenuMovil(false);
  }

  const success = () => {
    mensaje('success', 'Bienvenido', '')
  }

  let visualizar_secciones = secciones.map((seccion) => {
    return (
      <Link className="titles" to={seccion.url} goto={seccion.url} onClick={cerrarMenu}> 
          <span className="seccion" >
            {seccion.nombre}
          </span>
      </Link>
    );
  });

  return (
    <Row align="middle" className="contenedor_header">
      <Col xs={8} md={6} style={{ padding: '20px', textAlign: 'center' }}>
        <img alt="logo" className="log_header" src={ima} />
      </Col>
      <Col xs={{ span: 4, offset: 12 }} md={{ span: 0, offset: 0 }}>
        <MenuOutlined style={{ }} onClick={() => setMostrarMenuMovil(!mostrar_menu_movil)} />
      </Col>
      <Col xs={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }}>
        {mostrar_menu_movil &&
          <>

            {visualizar_secciones}
            {usuario ?
              <>
                <Link className="titles" to="/en-vivo" onClick={cerrarMenu}  style={{display:'non'}}>
                    <span className="seccion" >
                      En vivo
                    </span>
                </Link>
              </>
              :
              <>
                {
                  <Link className="titles" to="/en-vivo" onClick={()=>{ setMostrarLogin(true); cerrarMenu();}}>
                  <span className="seccion" >
                    En vivo
                  </span>
                </Link>}

              </>
            }

           
              <>
                {usuario ?
                  <Link className="titles" to="/cerrar-sesion" onClick={() => { setMostrarLogin(true); cerrarMenu(); }}  style={{display:'non'}}>
                   
                      <span className="seccion" >
                        Cerrar sesión
        </span>
                      
                  </Link>
                  :

                  <Link className="titles" to="/registro">
                   
                      <span className="seccion registro_class" >
                        REGISTRO
        </span>
                     
                    
                  </Link>
                }
              </>
            

          </>

        }

      </Col>
      <Col xs={{ span: 0, offset: 0 }} md={{ span: 10, offset: 0 }}>
      
          <>

            {visualizar_secciones}
            {usuario ?
              <>
                <Link className="titles" to="/en-vivo" onClick={cerrarMenu}  style={{display:'non'}}>
                    <span className="seccion" >
                      En vivo
                    </span>
                </Link>
              </>
              :
              <>
                {
                  <Link className="titles" to="/en-vivo" onClick={()=>{ setMostrarLogin(true); cerrarMenu();}}>
                  <span className="seccion" >
                    En vivo
                  </span>
                </Link>}

              </>
            }

          </>
      </Col>

      <Col xs={{ span: 0, offset: 0 }} md={{ span: 5, offset: 3 }}>
        {usuario ?
          <Link className="titles registro_class" to="/cerrar-sesion" onClick={() => { setMostrarLogin(true); cerrarMenu(); }}  style={{display:'non'}}>
           
              <span className="seccion" >
                Cerrar sesión
        </span>
             
          </Link>
          :
          <Link className="titles registro_class" to="/registro">
           
              <span className="seccion" >
                REGISTRO
        </span>
             
          </Link>
        }
      </Col>

      <Modal
        title={<span><strong>Inicia sesión</strong> <small>(únicamente con registro previo)</small></span>}
        visible={mostrar_login}
        onOk={() => { setMostrarLogin(false) }}
        onCancel={() => { setMostrarLogin(false) }}
        footer={null}
      >
        <Login onResponseCerrarLogin={() => { setMostrarLogin(false); }} onResponse={() => { setMostrarLogin(false); window.location.href = "/en-vivo"; }} onClick={success} />
      </Modal>

    </Row>
  );
}

export default Header;